import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import BrushFibercomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";

import BrushFiberimg4 from "../../assets/images/nimmin/agr/asset8.jpeg";
import bruscomp1 from "../../assets/images/nimmin/products/broomType2-7.png";
import bruscomp2 from "../../assets/images/nimmin/products/broomType2-8.png";
import bruscomp3 from "../../assets/images/nimmin/products/broomType2-9.png";
import brushImg from "../../assets/images/nimmin/products/hero6.png";




import { FaCloudDownloadAlt } from "react-icons/fa";

export const BrushFiber = () => {
  return (
    <>
      <BrushFiberCompo1 />
      {/* <BrushFiberCompo2 /> */}
    </>
  );
};

const BrushFiberCompo1 = () => {
  const handleLabelClick = () => {
    const pdfUrlpath = process.env.PUBLIC_URL + '/Broom_browser.pdf';
    downloadPdf(pdfUrlpath);
};

const downloadPdf = (url) => {
    const path = document.createElement('a');
    path.href = url;
    path.setAttribute('download', 'Broom_browser.pdf');
    document.body.appendChild(path);
    path.click();
    document.body.removeChild(path); 
};
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${BrushFibercomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <FirberBrusSlide />
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={BrushFiberimg3} alt="" /> */}
                <h1 className="text-4xl max-xm:text-3xl font-bold pb-2">House keeping brush fibre</h1>
              </div>
              <ul className=" list-disc ">
                <li className=" text-lg leading-8  ">
               
                Nimmin's Housekeeping Brush is a high-quality cleaning tool designed to make household cleaning tasks more efficient and effective. Crafted with durability and functionality in mind, this brush is ideal for various cleaning applications around the home.
                </li>
                <li className=" text-lg leading-8  ">
                Features a comfortable, easy-to-grip handle that reduces hand fatigue and allows for extended cleaning sessions without discomfort.
               </li>

               <li className=" text-lg leading-8  ">
               Equipped with strong, long-lasting bristles that effectively remove dirt, dust, and debris from a variety of surfaces.
               </li>

              </ul>


              <div className="flex px-3  justify-center items-center text-center bg-green-700 py-1 w-[160px] text-white rounded-full">
                <div>
                  {" "}
                  <FaCloudDownloadAlt className="text-lg font-semibold" />
                </div>
                <div>
                  <button className=" text-lg  font-semibol " onClick={handleLabelClick}>
                    Download Browser
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="w-full max-sm:mt-[50px] mt-[100px] " />
        </div>
        <div className=" container mx-auto justify-center   max-lg:py-[90px] max-sm:px-0  relative sm:mb-[296px] xl:mb-[60px] xl:mt-[60px] ">
        <img className=" h-[450px] max-sm:h-auto w-full rounded" src={brushImg} alt="" />
      </div>

        <div className="max-sm:mt-[0px] mt-[100px]">
          <div>
            <h1 className="text-white text-5xl max-sm:text-4xl font-bold max-sm:mt-[0px] mt-[100px] ">
              Features Vermi Compost
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-4" />
          </div>
          
          <div className="bg-white bg-opacity-40 rounded-3xl">
            <div className="bg-gray-400 rounded-3xl pt-3 pl-3 pr-3">
              <Tabs />
            </div>
          </div>
        </div>

     
      </section>
    </>
  );
};

// ---------------
const Tabs = () => {
  const [activeTab, setActiveTab] = useState("WhyBrushFiber");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="w-full mx-auto  bg-gray-200 rounded-3xl max-sm:px-2 px-4">
      <div className="flex justify-center border-b  w-full border-gray-300">
        <button
          className={`py-3 max-sm:py-1 px-4 max-sm:px-1  text-gray-700  text-lg max-sm:text-sm font-semibold  ${
            activeTab === "WhyBrushFiber" ? "bg-white rounded-2xl " : "text-black   "
          }`}
          onClick={() => handleTabClick("WhyBrushFiber")}
        >
          Why House keeping brush fibre
        </button>
        <button
          className={`py-3 px-10 max-sm:py-1 max-sm:px-1  text-gray-700  text-lg max-sm:text-sm font-semibold ${
            activeTab === "DosageApplications" ? "bg-white rounded-2xl" : "text-gray-500"
          }`}
          onClick={() => handleTabClick("DosageApplications")}
        >
          Uses
        </button>
      </div>
      <div className="mt-4">
        {activeTab === "WhyBrushFiber" && (
          <div>
            <h2 className="text-xl font-bold mb-2">Why BrushFiber</h2>
            <ul className="">
              <li className="textt-base leading-10">
           
              •  Ergonomic Design: Features a comfortable, easy-to-grip handle that reduces hand fatigue and allows for extended cleaning sessions without discomfort.
              </li>
              <li className="textt-base leading-10">
                • Ergonomic Design: Features a comfortable, easy-to-grip handle that reduces hand fatigue and allows for extended cleaning sessions without discomfort.
              </li>
              <li className="textt-base leading-10">
                • Durable Bristles: Equipped with strong, long-lasting bristles that effectively remove dirt, dust, and debris from a variety of surfaces.
              </li>

              <li className="textt-base leading-10">
              • Versatile Use: Suitable for cleaning different areas, including floors, carpets, upholstery, and hard-to-reach corners.
              </li>

              <li className="textt-base leading-10 ">
              • The brush is designed for easy cleaning and maintenance, with bristles that resist clumping and are simple to rinse and dry.
              </li>

              <li className="textt-base leading-10 pb-5 ">
              • Eco-Friendly Materials: Made from sustainable and eco-friendly materials, ensuring minimal environmental impact.</li>
            </ul>

          </div>
        )}
        {activeTab === "DosageApplications" && (
          <div className="w-full overflow-x-auto">
          <h2 className="text-xl font-bold mb-2">Dosage & Applications</h2>
          <div className="container mx-auto max-sm:p-2 p-4">

          <ul>
           <li className="textt-base leading-10 ">
           • For Floors: Use the brush to sweep and collect dirt and debris from hard floors or carpets. For carpets, use a gentle brushing motion to lift dirt without damaging the fibers.
            </li>
            <li className="textt-base leading-10 ">
           • For Upholstery: Gently brush upholstery to remove dust and pet hair. Ensure that you use light strokes to avoid damaging the fabric.
            </li>
            <li className="textt-base leading-10 pb-5 ">
            For Corners and Edges: Utilize the brush's shape to reach into corners and edges where dirt tends to accumulate.
            </li>
           
           </ul>
          </div>
        </div>
        
        )}
      </div>
    </div>
  );
};

export default Tabs;

// component 2



export const FirberBrusSlide = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-xl" src={bruscomp1} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex  justify-center ">
              <img className="h-[380px] rounded-xl" src={bruscomp2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex  justify-center ">
              <img className="h-[380px] rounded-xl" src={bruscomp3} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const FirberBrusSlide2 = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
