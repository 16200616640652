import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import liquidp1 from "../../assets/images/nimmin/products/liquidType3.7.png";
import spray1 from "../../assets/images/nimmin/products/newproduct/NIMMIN-SPRAY.png";
import { AboutCont } from "../Contact";
export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={spray1} alt="" />
            </div>
          </SwiperSlide>
         
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const NutrientSpray = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont />
    </>
  );
};

const ContactCompo1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider />
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold">
                  NutrientSpray{" "}
                </h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                  {" "}
                  Bio Nutrient Spray is an organic, eco-friendly fertilizer rich
                  in essential nutrients that promote plant growth and health.
                  This spray is typically created by fermenting banana peels,
                  which are high in potassium, calcium, magnesium, and other
                  vital minerals. These nutrients help strengthen plant roots,
                  enhance flowering, and improve fruit quality. The spray also
                  aids in balancing soil pH and boosting microbial activity,
                  which leads to healthier, more resilient plants. This natural
                  fertilizer is especially beneficial for fruit-bearing plants,
                  vegetables, and flowering plants, offering a sustainable
                  alternative to chemical fertilizers.
                </li>
              </ul>
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>
      </section>
    </>
  );
};
