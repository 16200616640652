import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
// Fiber img

import rayshabgImg1 from "../../assets/images/nimmin/raysha/asset18.jpeg";
import rayshabgImg2 from "../../assets/images/nimmin/raysha/asset19.jpeg";
import rayshabgImg3 from "../../assets/images/nimmin/raysha/asset20.webp";
import rayshabgImg4 from "../../assets/images/nimmin/raysha/asset21.jpeg";
import rayshabgImg5 from "../../assets/images/nimmin/raysha/asset22.jpeg";

//
import rayshaimg1 from "../../assets/images/nimmin/nimlogo3.png";
import rayshaimg5 from "../../assets/images/nimmin/raysha/asset 7.png";
import rayshaimg6 from "../../assets/images/nimmin/raysha/asset 9.webp";
import rayshaimg7 from "../../assets/images/nimmin/raysha/asset 10.webp";
import rayshaimg8 from "../../assets/images/nimmin/raysha/asset 11.png";

import vybranaimg6 from "../../assets/images/nimmin/vybrana/asset 10.jpeg";
export const Raysha = () => {
  return (
    <>
      <RayshaComp1 />
      <Rayshacomp2 />
      <Rayshacomp3 />
      <Rayshacomp4 />
      <Rayshacomp5 />
    </>
  );
};

const RayshaComp1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.0), rgba(0,0,0, 0.0)),url(${rayshabgImg1})`,
          backgroundSize: "100% 100%",
          // backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[120px] max-sm:mt-[160px] sm:px-[0px]">
          <div className="container  bg-white bg-opacity-60 rounded-3xl">
            <div className="  p-6  max-sm:col-span-9 sm:col-span-6">
              <div>
                <div className=" flex justify-center">
                  <img className="h-[170px]" src={rayshaimg1} alt="" />
                </div>

                <h1 className="text-black text-3xl mt-2 max-sm:text-4xl text-center font-bold">
                  Nimmin - Truly Sustainable Superfibre
                </h1>
                <hr className=" text-center bg-yellow-500 p-1 w-[0px] my-1" />
              </div>

              <p className="text-xl max-sm:text-base font-semibold text-black leading-9 mt-2">
                Gencrest proudly presents Vybrana, the groundbreaking cellulosic
                fibre upcycled from banana biomass using a revolutionary green
                enzymatic technology. This zero-waste optimized process delivers
                a scientifically superior fibre that benefits both you and the
                environment
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-10 mt-10 sm:grid-cols-2 lg:grid-cols-3">
            <div className="group relative col-span-1 w-full h-full bg-white text-black p-4 rounded-lg shadow-lg">
              <img
                src={rayshabgImg4}
                alt="Card Image"
                className="w-full px-12 py-4 h-48 object-cover rounded-t-lg"
              />
              <h2 className="text-lg font-bold mt-4 text-center">
                Card Heading
              </h2>
              <div className="absolute inset-0 bg-white items-center justify-center text-center transform transition-transform duration-300  hidden group-hover:flex group-hover:rounded-2xl rounded-lg">
                <div>
                  <h2 className="text-xl font-bold mb-2 mt-5">
                    Full Description
                  </h2>
                  <p className="text-lg px-2 text-gray-600">
                    This is the full description of the card. It appears when
                    the card is hovered and flips vertically.
                  </p>
                </div>
              </div>
            </div>

            <div className="group relative col-span-1 w-full h-full bg-white text-black p-4 rounded-lg shadow-lg">
              <img
                src={rayshabgImg4}
                alt="Card Image"
                className="w-full px-12 py-4 h-48 object-cover rounded-t-lg"
              />
              <h2 className="text-lg font-bold mt-4 text-center">
                Card Heading
              </h2>
              <div className="absolute inset-0 bg-white items-center justify-center text-center transform transition-transform duration-300  hidden group-hover:flex group-hover:rounded-2xl rounded-lg">
                <div>
                  <h2 className="text-xl font-bold mb-2 mt-5">
                    Full Description
                  </h2>
                  <p className="text-lg px-2 text-gray-600">
                    This is the full description of the card. It appears when
                    the card is hovered and flips vertically.
                  </p>
                </div>
              </div>
            </div>

            <div className="group relative col-span-1 w-full h-full bg-white text-black p-4 rounded-lg shadow-lg">
              <img
                src={rayshabgImg4}
                alt="Card Image"
                className="w-full px-12 py-4 h-48 object-cover rounded-t-lg"
              />
              <h2 className="text-lg font-bold mt-4 text-center">
                Card Heading
              </h2>
              <div className="absolute inset-0 bg-white items-center justify-center text-center transform transition-transform duration-300  hidden group-hover:flex group-hover:rounded-2xl rounded-lg">
                <div>
                  <h2 className="text-xl font-bold mb-2 mt-5">
                    Full Description
                  </h2>
                  <p className="text-lg px-2 text-gray-600">
                    This is the full description of the card. It appears when
                    the card is hovered and flips vertically.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr className="w-full mt-[60px] " />
        </div>
      </section>
    </>
  );
};

// ---------------
// component 2

const Rayshacomp2 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0, 0.5)),url(${rayshabgImg2})`,
          backgroundSize: "cover",
          // backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div className="flex justify-center">
            <img className=" max-h-[550px] " src={rayshaimg5} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};
//

const Rayshacomp3 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px]  items-center bg-white"
        // style={{
        //   backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${fiberbgimg3})`,
        //   backgroundSize: "100% 100%",
        //   backgroundAttachment: "fixed",
        // }}
      >
        <div>
          <div>
            <h1 className="text-black text-4xl max-sm:text-3xl font-semibold mt-[0px]">
              Green Attributes
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>
          <VybranaSlider1 />
        </div>
      </section>
    </>
  );
};
const Rayshacomp4 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)),url(${rayshabgImg3})`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div>
            <h1 className="text-white text-4xl max-sm:text-3xl font-semibold mt-[0px]">
              Hemp Properties
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>

          <div className="flex justify-center mt-5">
            <img className=" lg:h-[450px]" src={rayshaimg8} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

const Rayshacomp5 = () => {
  return (
    <>
      <section
        className=" HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0, 0.5)),url(${rayshabgImg4})`,
          backgroundSize: "cover",
          // backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div>
            <h1 className="text-white text-4xl max-sm:text-3xl font-semibold mt-[0px]">
              Yarns in Raysha blends{" "}
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>

          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
            <div className="group relative col-span-1 w-full h-full bg-white text-black p-4 rounded-lg shadow-lg">
              <img
                src={rayshabgImg4}
                alt="Card Image"
                className="w-full px-12 py-4 h-48 object-cover rounded-t-lg"
              />
              <h2 className="text-lg font-bold mt-4 text-center">
                Card Heading
              </h2>
              <div className="absolute inset-0 bg-white items-center justify-center text-center transform transition-transform duration-300  hidden group-hover:flex group-hover:rounded-2xl">
                <div>
                  <h2 className="text-xl font-bold mb-2 mt-5">
                    Full Description
                  </h2>
                  <p className="text-lg px-2 text-gray-600">
                    This is the full description of the card. It appears when
                    the card is hovered and flips vertically.
                  </p>
                </div>
              </div>
            </div>

            <div className="group relative col-span-1 w-full h-full bg-white text-black p-4 rounded-lg shadow-lg">
              <img
                src={rayshabgImg4}
                alt="Card Image"
                className="w-full px-12 py-4 h-48 object-cover rounded-t-lg"
              />
              <h2 className="text-lg font-bold mt-4 text-center">
                Card Heading
              </h2>
              <div className="absolute inset-0 bg-white items-center justify-center text-center transform transition-transform duration-300  hidden group-hover:flex group-hover:rounded-2xl">
                <div>
                  <h2 className="text-xl font-bold mb-2 mt-5">
                    Full Description
                  </h2>
                  <p className="text-lg px-2 text-gray-600">
                    This is the full description of the card. It appears when
                    the card is hovered and flips vertically.
                  </p>
                </div>
              </div>
            </div>

            <div className="group relative col-span-1 w-full h-full bg-white text-black p-4 rounded-lg shadow-lg">
              <img
                src={rayshabgImg4}
                alt="Card Image"
                className="w-full px-12 py-4 h-48 object-cover rounded-t-lg"
              />
              <h2 className="text-lg font-bold mt-4 text-center">
                Card Heading
              </h2>
              <div className="absolute inset-0 bg-white items-center justify-center text-center transform transition-transform duration-300  hidden group-hover:flex group-hover:rounded-2xl">
                <div>
                  <h2 className="text-xl font-bold mb-2 mt-5">
                    Full Description
                  </h2>
                  <p className="text-lg px-2 text-gray-600">
                    This is the full description of the card. It appears when
                    the card is hovered and flips vertically.
                  </p>
                </div>
              </div>
            </div>

            <div className="group relative col-span-1 w-full h-full bg-white text-black p-4 rounded-lg shadow-lg">
              <img
                src={rayshabgImg4}
                alt="Card Image"
                className="w-full px-12 py-4 h-48 object-cover rounded-t-lg"
              />
              <h2 className="text-lg font-bold mt-4 text-center">
                Card Heading
              </h2>
              <div className="absolute inset-0 bg-white items-center justify-center text-center transform transition-transform duration-300  hidden group-hover:flex group-hover:rounded-2xl">
                <div>
                  <h2 className="text-xl font-bold mb-2 mt-5">
                    Full Description
                  </h2>
                  <p className="text-lg px-2 text-gray-600">
                    This is the full description of the card. It appears when
                    the card is hovered and flips vertically.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Rayshacomp9 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(58, 58, 58, 0.1), rgba(58, 58, 58, 0.1)),url(${rayshabgImg5})`,
          backgroundSize: "100% 100%",
          // backgroundAttachment: "fixed",
        }}
      ></section>
    </>
  );
};

export const VybranaSlider1 = () => {
  return (
    <>
      <div>
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex justify-center gap-5 mt-5">
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-6 max-sm:col-span-12  sm:col-span-full lg:col-span-6 ">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={rayshaimg6}
                    alt=""
                  />
                </div>
                <div className="max-sm:col-span-12  sm:col-span-full lg:col-span-6  flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center gap-5 mt-5">
              <div className="grid grid-cols-12 gap-5">
                <div className="max-sm:col-span-12  sm:col-span-full lg:col-span-6">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={vybranaimg6}
                    alt=""
                  />
                </div>
                <div className="max-sm:col-span-12  sm:col-span-full lg:col-span-6 flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center gap-5 mt-5">
              <div className="grid grid-cols-12 gap-5">
                <div className="max-sm:col-span-12 sm:col-span-full lg:col-span-6">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={rayshaimg7}
                    alt=""
                  />
                </div>
                <div className="max-sm:col-span-12 sm:col-span-full lg:col-span-6 flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="relative">
          <div>
            <div className="button-next absolute top-[-160px] hover:bg-yellow-400 z-20 transform -translate-y-1/2 right-4 bg-black bg-opacity-60 w-[45px] p-1 text-black-200 cursor-pointer">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-160px] hover:bg-yellow-400 z-20 transform -translate-y-1/2 left-4 bg-black bg-opacity-60 w-[45px] p-1 text-black-200 cursor-pointer">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
