import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";

import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { AboutCont } from "../Contact";
import drinks1 from "../../assets/images/nimmin/products/newproduct/drink.jpg";

export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={drinks1} alt="" />
            </div>
          </SwiperSlide>
         
     
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Drinks = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont/>
    
   
    </>
  );
};


const ContactCompo1 = () => {
    return (
      <>
         <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider/>
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold">  Bio-Food like Drinks  </h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                Beverages made from natural extracts, including smoothies and health drinks. For example, Banana-based drinks are a nutritious and delicious bio-food option, offering a natural way to enjoy the fruit’s rich flavor and health benefits. These drinks, such as banana smoothies, shakes, and flavored waters, are packed with essential nutrients like potassium, vitamin B6, and dietary fiber. They support heart health, energy production, and digestive function, making them ideal for a quick, healthy boost. Bananas add natural sweetness and creaminess to drinks, reducing the need for added sugars and artificial ingredients. They blend well with other fruits, vegetables, and proteins, making them versatile in various beverage recipes. Additionally, banana-based drinks can help reduce food waste by utilizing overripe bananas, contributing to a more sustainable and environmentally friendly food system.
                </li>

                <li className="py-2">
                Contains a balanced mix of macro and micronutrients essential for plant growth, including nitrogen, phosphorus, potassium, calcium, and magnesium.
                </li>
                <li className="py-2">
                Suitable for a wide range of plants, including vegetables, fruits, flowers, and ornamental plants.
                </li>
           
              </ul>
              <div className="flex px-3  justify-center items-center text-center bg-green-700 py-1 w-[160px] text-white rounded-full">
                <div>
                  {" "}
                  <FaCloudDownloadAlt className="text-lg font-semibold" />
                </div>
                <div>
                  <button className=" text-lg  font-semibol ">
                    Download Browser
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>

    
      </section>
      </>
    );
  };

