import './App.css';
import { HomePage } from './pages/HomeLayout';
function App() {
  return (
  <div>
    <HomePage/>
  </div>  
  );
}

export default App;
