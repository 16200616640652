import React, { useEffect } from "react";
import inovcomp1 from "../assets/images/nimmin/inovation/asset4.jpeg";
import inovcomp2 from "../assets/images/nimmin/inovation/asset5.jpeg";
import inovcomp3 from "../assets/images/nimmin/BIO-FIBER2.jpg";
import inovcomp4 from "../assets/images/nimmin/BIO-construction.jpg";
import inovcomp5 from "../assets/images/nimmin/medical.jpg";
import inovcomp6 from "../assets/images/nimmin/biofoods-aata.jpg";
import inovcomp7 from "../assets/images/nimmin/bio-color.jpg";
import deys from "../assets/images/nimmin/products/newproduct/dyes1.jpg";


import AOS from "aos";
import "aos/dist/aos.css";
export const Inovation = () =>{
    return(
       <>
        <InovationCompo1/>  
        <BioAgri/>
        <BioFibre/>

        <BioConstruction/>
         <BioMedicinalProducts/>
         <BioFoodProducts/>
         <BioColorantsDyes/>
        </>
    )
}

const InovationCompo1 = () => {
    return (
      <>
        <section
          className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center  h-[100vh]"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)),url(${inovcomp1})`,
            backgroundSize: "100% 100%",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="max-sm:mt-[100px] mt-[25vh]">
            <h1 className="text-5xl text-start max-sm:text-3xl font-semibold text-white leading-[60px] tracking-wide	 ">
            Innovative bridge between problems <br /> and solutions, Nimmin turning possibilities into reality for a sustainable future.

            </h1>
  
            <hr className="w-full mt-[100px] " />
            <h1 className="mt-4 text-xl max-sm:text-base font-semibold tracking-widest open-sans  text-white">
            Pioneering Tomorrow's Solutions Today.
            <br />
              
            </h1>
          </div>
        </section>
      </>
    );
  };

  const BioAgri = () => {
    useEffect(() => {
      AOS.init({
        duration: 700,
        easing: "ease-out-cubic",
      });
    }, []);
    return (
      <>
        <section>
       
          <div
            className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px] lg:h-[100vh]  "
            style={{
              backgroundImage: `linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)),url(${inovcomp2})`,
              backgroundSize: "100% 100%",
              backgroundAttachment: "fixed",
            }}
          >
               <div>
            <h1 className="text-white text-6xl max-sm:text-4xl font-bold " data-aos="fade-down">
            Bio Agriculture
            </h1>
            <hr className="text-white bg-white p-1 w-[10px] my-1" />
          </div>
            <div className="grid grid-cols-12 gap-5 bg-black bg-opacity-30 p-6 lg:h-[400px]" data-aos="fade-down">
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Liquid fertilizers
                </h1>
                <p className="text-white mt-4">Fertilizers made from organic waste, promoting healthy plant growth.</p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Nutrient Spray
                </h1>
                <p className="text-white mt-4"> Foliar sprays rich in nutrients, supporting plant health.</p>
              </div>
  
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Vermicompost
                </h1>
                <p className="text-white mt-4">Compost produced using worms to break down organic waste, enhancing soil fertility.</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };


  const BioFibre = () => {
    useEffect(() => {
      AOS.init({
        duration: 700,
        easing: "ease-out-cubic",
      });
    }, []);
    return (
      <>
        <section>
       
          <div
            className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px] max-sm:h-auto h-[100vh]  "
            style={{
              backgroundImage: `linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)),url(${inovcomp3})`,
              backgroundSize: "100% 100%",
              backgroundAttachment: "fixed",
            }}
          >
               <div>
            <h1 className="text-white text-6xl max-sm:text-4xl font-bold "  data-aos="fade-up">
            Bio Fibre
            </h1>
            <hr className="text-white bg-white p-1 w-[10px] my-1" />
          </div>
            <div className="grid grid-cols-12 gap-5 bg-black bg-opacity-30 p-6 lg:h-[400px] " data-aos="fade-right">
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Textiles 
                </h1>
                <p className="text-white mt-4">Used in producing eco-friendly clothing and other textile products.</p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Packaging 
                </h1>
                <p className="text-white mt-4">Sustainable packaging materials made from natural fibers and byproducts.</p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Paper
                </h1>
                <p className="text-white mt-4">Pulp based paper products, including writing paper, cardboard, and packaging.</p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Handicraft
                </h1>
                <p className="text-white mt-4">Craft materials made from natural fibers, supporting artisanal work.</p>
              </div>
  
              
            </div>
          </div>
        </section>
      </>
    );
  };

  const BioConstruction = () => {
    useEffect(() => {
      AOS.init({
        duration: 700,
        easing: "ease-out-cubic",
      });
    }, []);
    return (
      <>
        <section>
       
          <div
            className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px] max-sm:h-auto h-[100vh]  "
            style={{
              backgroundImage: `linear-gradient(rgba(0,0,0, 0.4), rgba(0,0,0, 0.4)),url(${inovcomp4})`,
              backgroundSize: "100% 100%",
              backgroundAttachment: "fixed",
            }}
          >
               <div>
            <h1 className="text-white text-6xl max-sm:text-4xl font-bold " data-aos="fade-right">
            Bio Construction
            </h1>
            <hr className="text-white bg-white p-1 w-[10px] my-1" />
          </div>
            <div className="grid grid-cols-12 gap-5 bg-black bg-opacity-30 p-6 lg:h-[400px]" data-aos="fade-right" >
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Wood                </h1>
                <p className="text-white mt-4">Sustainable wood-like materials for construction and furniture.
                </p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Construction
                </h1>
                <p className="text-white mt-4">Bio-composites and other building materials like bricks derived from organic byproducts.
                </p>
              </div>
              
  
              
            </div>
          </div>
        </section>
      </>
    );
  };


  const BioMedicinalProducts = () => {
    useEffect(() => {
      AOS.init({
        duration: 700,
        easing: "ease-out-cubic",
      });
    }, []);
    return (
      <>
        <section>
       
          <div
            className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px] max-sm:h-auto h-[100vh]  "
            style={{
              backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${inovcomp5})`,
              backgroundSize: "100% 100%",
              backgroundAttachment: "fixed",
            }}
          >
               <div>
            <h1 className="text-white text-6xl max-sm:text-4xl font-bold " data-aos="fade-right">
            Bio-Medicinal Products
            </h1>
            <hr className="text-white bg-white p-1 w-[10px] my-1" />
          </div>
            <div className="grid grid-cols-12 gap-5 bg-black bg-opacity-40 p-6 lg:h-[400px]" >
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 " data-aos="fade-down">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Medicines  </h1>
                <p className="text-white mt-4">Pharmaceutical products derived from the medicinal properties of natural ingredients.</p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 " data-aos="fade-down">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Minerals
                </h1>
                <p className="text-white mt-4">Mineral supplements sourced from organic byproducts.</p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 " data-aos="fade-down">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Nutrients

                </h1>
                <p className="text-white mt-4">Nutrient-rich supplements designed to support health and wellness, made from organic sources.</p>
              </div>
  
              
            </div>
          </div>
        </section>
      </>
    );
  };



  const BioFoodProducts = () => {
    useEffect(() => {
      AOS.init({
        duration: 700,
        easing: "ease-out-cubic",
      });
    }, []);
    return (
      <>
        <section>
       
          <div
            className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px] max-sm:h-auto h-[100vh]  "
            style={{
              backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${inovcomp6})`,
              backgroundSize: "100% 100%",
              backgroundAttachment: "fixed",
            }}
          >
               <div>
            <h1 className="text-white text-6xl max-sm:text-4xl font-bold ">
            Bio-Food Products
            </h1>
            <hr className="text-white bg-white p-1 w-[10px] my-1" />
          </div>
            <div className="grid grid-cols-12 gap-5 bg-black bg-opacity-30 p-6 lg:h-[400px]" data-aos="fade-down">
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Flour          </h1>
                <p className="text-white mt-2">Used in baking, cooking, and as a gluten-free alternative.</p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Baby Food
                </h1>
                <p className="text-white mt-2">CNutritious and natural baby food products made from organic byproducts.</p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Bakery Products
                </h1>
                <p className="text-white mt-2">Baked goods such as bread, cakes, and pastries using eco-friendly ingredients.
                </p>
              </div>

              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Chips
                </h1>
                <p className="text-white mt-2">Healthy snack options made from organic slices.
                </p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Drinks
                </h1>
                <p className="text-white mt-2">Beverages made from natural extracts, including smoothies and health drinks.
                </p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Pickles
                </h1>
                <p className="text-white mt-2">Flavorful pickles made from organic parts, offering a unique taste.
                </p>
              </div>
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Candies
                </h1>
                <p className="text-white mt-2">Sweet treats made from natural ingredients, providing a healthy option
                </p>
              </div>
  
              
            </div>
          </div>
        </section>
      </>
    );
  };


  const BioColorantsDyes = () => {
    useEffect(() => {
      AOS.init({
        duration: 700,
        easing: "ease-out-cubic",
      });
    }, []);
    return (
      <>
        <section>
       
          <div
            className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px] max-sm:h-auto h-[100vh]  "
            style={{
              backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${deys})`,
              backgroundSize: "100% 100%",
              backgroundAttachment: "fixed",
            }}
          >
               <div>
            <h1 className="text-white text-6xl max-sm:text-4xl font-bold ">
            Bio-Colorants and Dyes            </h1>
            <hr className="text-white bg-white p-1 w-[10px] my-1" />
          </div>
            <div className="grid grid-cols-12 gap-5 bg-black bg-opacity-30 p-6 lg:h-600px]" data-aos="fade-down">
              <div className=" lg:col-span-4 max-sm:col-span-12 sm:col-span-12  mt-8 ">
                <h1 className="text-lg open-sans font-medium text-white uppercase border-l-2  px-1 border-white  ">
                Mordant or Dye   </h1>
                <p className="text-white mt-4">Natural dyes for textiles and other materials, extracted from organic waste.</p>
              </div>
           
              
            </div>
          </div>
        </section>
      </>
    );
  };