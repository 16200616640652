import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";

import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { AboutCont } from "../Contact";
import candy1 from "../../assets/images/nimmin/products/newproduct/candy.jpg";

export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={candy1} alt="" />
            </div>
          </SwiperSlide>
        
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Candies = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont />
    </>
  );
};

const ContactCompo1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider />
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold">
                  {" "}
                  Bio-Food like Candies{" "}
                </h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                  Sweet treats made from natural ingredients, providing a
                  healthy option. Bio-foods, such as candies made from bananas,
                  offer a sustainable and nutritious alternative to traditional
                  sweets. These candies are created using natural ingredients,
                  primarily ripe bananas, which provide a rich source of
                  vitamins, minerals, and fiber. By utilizing bananas, which
                  might otherwise go to waste, bio-food producers reduce food
                  waste and promote sustainability. Unlike conventional candies
                  loaded with artificial flavors, colors, and sugars,
                  banana-based candies often have a naturally sweet taste and do
                  not require added sugars or preservatives. This makes them a
                  healthier snack option for those looking to enjoy a sweet
                  treat without compromising on nutrition. Additionally,
                  bio-foods support environmentally friendly practices,
                  promoting a healthier lifestyle and a greener planet.
                </li>
              </ul>
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>
      </section>
    </>
  );
};




