import React from 'react';
import { FaInstagram, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { MdEmail, MdTextsms } from 'react-icons/md';
import middLogo from "../assets/images/nimmin/nimlogo6.png";
import { navItems } from '../navbar/Navbar';
const Footer = () => {
  const WhatsAppIcon = ({ phoneNumber, message }) => {
    const handleClick = () => {
      const whatsappMessage = encodeURIComponent(message);
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${whatsappMessage}`;
      window.open(whatsappURL, "_blank");
    };

    return (
      <div
        onClick={handleClick}
        style={{ cursor: "pointer", marginRight: "10px" }}
      >
        <FaWhatsapp size={32} />
      </div>
    );
  };



  return (
    <footer className="footerbg footertext py-4 bg-color">
      <div className="container mt-4 mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        <div className="flex flex-col md:flex-row items-center">
          <a href="/">   
            <img
              src={middLogo}
              alt="Nimmin Logo"
              className="h-[60px] mr-2"
              style={{ width: "100%" }}
            />
          </a>
        </div>
        
        <Footer2/>
        <div className="flex mt-4 md:mt-0">
          <ul className="flex gap-5 justify-end text-3xl">
            <li className="hover:text-blue-400 text-white">
              <a href="https://www.instagram.com/nimminorganic/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
            </li>
            <li className="hover:text-blue-400 text-white">
              <a href="https://twitter.com/Nimmin221021" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
            </li>
            <li className="hover:text-blue-400 text-white">
              <WhatsAppIcon
                phoneNumber="+8595903939"
                message="Your message for WhatsApp here"
              />
            </li>
          
          </ul>
        </div>
      </div>
      <div className="container mx-auto text-white my-4 text-center">
        <p>&copy; copyright and legal notice 2024</p>      
      </div>
    </footer>
  );
};

export default Footer;



const Footer2 = () => {
  return (
    <footer className=" py-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-start">
        
          <div className="w-full sm:w-auto flex flex-col sm:flex-row sm:space-x-8 mb-6 sm:mb-0">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.path}
                className="text-white hover:text-white text-[16px] mb-2 sm:mb-0"
              >
                {item.name}
              </a>
            ))}
            
          </div>
          <div className="w-full sm:w-auto mb-6 sm:mb-0">
          </div>
        </div>      
      </div>
    </footer>
  );
};


