import React from "react";
import contactcomp1 from "../assets/images/nimmin/contact.jpg";
import contbg from "../assets/images/nimmin/raysha/asset10.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";

import shadeImg2 from "../assets/images/nimmin/vector(13).png";

export const ContactUs = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont />
    </>
  );
};

const ContactCompo1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center  h-[100vh]"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)),url(${contactcomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="max-sm:mt-[100px] mt-[25vh]">
          <h1 className="text-5xl text-start max-sm:text-3xl font-semibold text-white leading-[60px] tracking-wide	 ">
            Keep your eyes <br /> on the stars and your stars and your <br />{" "}
            feet on the ground.
          </h1>

          <hr className="w-full mt-[100px] " />
          <h1 className="mt-4 text-lg max-sm:text-base font-semibold tracking-widest open-sans  text-white">
            In the quest for a greener future, we don't just seek solutions;{" "}
            <br /> we ignite a revolution where nature and technology harmonize
            to create a better world.
          </h1>
        </div>
      </section>
    </>
  );
};

export const AboutCont = () => {
  return (
    <>
      <section>
        <div
          className="HomeCompo1 sm:p-[50px] max-sm:px-[20px] max-sm:py-[20px]  bg-yellow-400 bg-color  "
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)),url(${contbg})`,
            backgroundSize: "100% 100%",
            backgroundAttachment: "fixed",
          }}
        >
          <section className=" mx-auto  ">
            <div>
              {/* <img className="h-[70px]" src={shadeImg1} alt="" /> */}
            </div>
            <div className="container mx-auto max-sm:w-[100%] w-100%] py-3 px-5 max-sm:px-2  rounded-md">
              <div className="text-center mb-3">
                <h1 className="text-5xl mb-4 mt-4 font-bold text-white ">
                  Contact Us
                </h1>
                <p className="text-base font text-white">
                  Any question or remarks? Just write us a message!
                </p>
              </div>
              <div className="  flex max-sm:flex-col sm:flex-col lg:flex-row justify-between gap-5 text-white  ">
                {/* // */}
                <div className="container w-full max-sm:w-full lg:w-1/2 bgcont text-center max-sm:px-5 sm:px-8 xl:px-16 rounded lg:h-[520px]  ">
                  <div className="bgcont2 z-50 h-[160px] w-[160] relative right-[65px]"></div>
                  <div className="relative bottom-[90px]">
                    <h1 className="text-2xl mb-2 font-bold">
                      Contact Information
                    </h1>
                    <p className="">Say something to start a live chat!</p>
                    <div className="mt-10 ">
                      <p className="text-sm mt-4">
                        <FontAwesomeIcon icon={faPhone} className="mr-2" />
                        <a href="tel:+918595903939">+91 8595903939</a>
                      </p>
                      <p className="text-sm mt-4">
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                        <a href="mailto:support@equipmedy.com">support@equipmedy.com</a>

                      </p>
                      <p className="text-sm mt-4">
                        <FontAwesomeIcon icon={faMapMarker} className="mr-2" />
                        B-99, 5th Floor, Phase-I Panchsheel Park Malviya Nagar,
                        Near Triveni Complex New Delhi - 110017
                      </p>
                    </div>
                  </div>
                </div>

                <div className="container w-full max-sm:w-full lg:w-full">
                  <div className=" sm:w m-auto p-6 bg-black rounded-xl bg-opacity-30 ">
                    <h2 className="text-2xl font-semibold mb-4"></h2>

                    <div>
                      <form>
                        <div className="container Forms grid max-2xl:grid-cols-2 max-xl:grid-cols-2 max-lg:grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 gap-5">
                          <div className="max-sm:flex-col max-sm:items-start mb-2 items-center">
                            <label className="min-w-[140px] block text-base font-medium text-white">
                              First Name
                            </label>
                            <input
                              type="text"
                              name="firstname"
                              id="firstName"
                              className="mt-1 py-1 px-2 w-full border-b-2 outline-none text-black bg-white"
                              placeholder="First name"
                              required
                            />
                          </div>
                          <div className="max-sm:flex-col max-sm:items-start mb-2 items-center">
                            <label className="min-w-[140px] block text-base font-medium text-white">
                              Last name
                            </label>
                            <input
                              type="text"
                              name="Last name"
                              id="lastName"
                              className="mt-1 py-1 px-2 w-full border-b-2 outline-none text-black bg-white"
                              placeholder="Last name."
                              required
                            />
                          </div>
                          <div className="max-sm:flex-col max-sm:items-start mb-2 items-center">
                            <label className="min-w-[140px] block text-base font-medium text-white">
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="mt-1 py-1 px-2 w-full border-b-2 outline-none text-black bg-white"
                              placeholder="Email"
                              required
                            />
                          </div>
                          <div className="max-sm:flex-col max-sm:items-start mb-2 items-center">
                            <label className="min-w-[140px] block text-base font-medium text-white">
                              Phone Number
                            </label>
                            <input
                              type="number"
                              name="pNumber"
                              id="pNumber"
                              className="mt-1 py-1 px-2 w-full border-b-2 outline-none text-black bg-white"
                              placeholder="Phone Number"
                              required
                            />
                          </div>

                          <div className="mb-4">
                            <h1 className="text-start font-medium text-white mb-4 w-full sm:w-auto">
                              Select Subject
                            </h1>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
                              <div className="flex items-center gap-4 mt-3">
                                <input
                                  type="radio"
                                  name="subject"
                                  value="general-inquiry"
                                  className="form-radio h-5 w-5 text-white"
                                  required
                                />
                                <span className="text-white text-sm w-48">
                                  General Inquiry
                                </span>
                              </div>

                              <div className="flex items-center gap-4 mt-3">
                                <input
                                  type="radio"
                                  name="subject"
                                  value="product-inquiry"
                                  className="form-radio h-5 w-5 text-white"
                                  required
                                />
                                <span className="text-white text-sm w-48">
                                  Product Inquiry
                                </span>
                              </div>

                              <div className="flex items-center gap-4 mt-3">
                                <input
                                  type="radio"
                                  name="subject"
                                  value="advertisement-inquiry"
                                  className="form-radio h-5 w-5 text-white"
                                  required
                                />
                                <span className="text-white text-sm w-48">
                                  Advertisement Inquiry
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="max-sm:flex-col ml-5 max-sm:items-start mb-2 items-center mt-5">
                              <label className="min-w-[140px] block text-base font-medium text-white">
                                Message
                              </label>
                              <textarea
                                className="mt-1 py-1 px-2 w-full border-b-2 outline-none text-black bg-white"
                                name="message"
                                id="message"
                                cols="30"
                                rows="3"
                              ></textarea>
                            </div>
                            <div className="flex lg:justify-end mt-5 sm:justify-center max-sm:justify-center">
                              <button className="sendBtn text-white bgcont py-2 px-4 rounded">
                                Send Message
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <img className="h-[90px]" src={shadeImg2} alt="" />
            </div>
          </section>
        </div>
      </section>
    </>
  );
};
