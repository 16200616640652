import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import vybranabgImg1 from "../../assets/images/nimmin/vybrana/asset21.webp";
import vybranabgImg2 from "../../assets/images/nimmin/vybrana/asset22.webp";
import vybranabgImg3 from "../../assets/images/nimmin/vybrana/asset23.webp";
import vybranabgImg4 from "../../assets/images/nimmin/vybrana/asset24.jpeg";
import vybranabgImg5 from "../../assets/images/nimmin/vybrana/asset25.jpeg";
import vybranabgImg6 from "../../assets/images/nimmin/vybrana/asset26.jpeg";

import vybranabgImg11 from "../../assets/images/nimmin/vybrana/asset31.png";

//
import vybranaimg1 from "../../assets/images/nimmin/nimlogo3.png";
import vybranaimg2 from "../../assets/images/nimmin/vybrana/asset 4.webp";
import vybranaimg3 from "../../assets/images/nimmin/vybrana/asset 5.png";
import vybranaimg4 from "../../assets/images/nimmin/vybrana/asset 6.webp";
import vybranaimg5 from "../../assets/images/nimmin/vybrana/asset 9.webp";
import vybranaimg6 from "../../assets/images/nimmin/vybrana/asset 10.jpeg";
import vybranaimg7 from "../../assets/images/nimmin/vybrana/asset 11.jpeg";
import vybranaimg8 from "../../assets/images/nimmin/vybrana/asset 12.jpeg";
import vybranaimg9 from "../../assets/images/nimmin/vybrana/asset 13.png";
import vybranaimg10 from "../../assets/images/nimmin/vybrana/asset 14.webp";
import vybranaimg11 from "../../assets/images/nimmin/vybrana/asset 15.webp";

import vybranaimg12 from "../../assets/images/nimmin/vybrana/asset 16.webp";
import vybranaimg13 from "../../assets/images/nimmin/vybrana/asset 17.webp";
import vybranaimg14 from "../../assets/images/nimmin/vybrana/asset 18.webp";

export const Vybrana = () => {
  return (
    <>
      <VybranaComp1 />
      <Vybranacomp2 />
      <Vybranacomp3 />
      <Vybranacomp4 />
      <Vybranacomp5 />
      <Vybranacomp6 />

      <Vybranacomp7 />
      <Vybranacomp8 />
    </>
  );
};

const VybranaComp1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.0), rgba(0,0,0, 0.0)),url(${vybranabgImg1})`,
          backgroundSize: "100% 100%",
          // backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[120px] max-sm:mt-[160px] sm:px-[80px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-60 rounded-3xl">
            <div className="  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                <div className=" flex justify-center">
                  <img className="h-[140px]" src={vybranaimg1} alt="" />
                </div>

                <h1 className="text-black text-3xl mt-2 max-sm:text-3xl text-center font-bold">
                  Nimminn Truly Sustainable Superfibre
                </h1>
                <hr className=" bg-yellow-500 p-1 w-[0px] my-1" />
              </div>

              <p className="text-xl  max-sm:text-lg font-semibold text-black leading-9 mt-2">
                Nimmin proudly presents Vybrana, the groundbreaking cellulosic
                fibre upcycled from banana biomass using a revolutionary green
                enzymatic technology. This zero-waste optimized process delivers
                a scientifically superior fibre that benefits both you and the
                environment
              </p>
            </div>
          </div>

          <hr className="w-full mt-[60px] " />
        </div>
      </section>
    </>
  );
};

// ---------------
// component 2

const Vybranacomp2 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0, 0.5)),url(${vybranabgImg2})`,
          backgroundSize: "cover",
          // backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div className="flex justify-center">
            <img className="h-[280px]" src={vybranaimg2} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};
//

const Vybranacomp3 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0, 0.5)),url(${vybranabgImg3})`,
          backgroundSize: "cover",
          // backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div className="flex justify-center">
            <img className="h-[450px]" src={vybranaimg3} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

const Vybranacomp4 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)),url(${vybranabgImg4})`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div>
            <h1 className="text-white text-4xl max-sm:text-3xl font-semibold mt-[0px]">
              The Power of Green Technology
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>
          <div className=" bg-gray-500 bg-opacity-70 p-4 md:p-16 rounded-3xl mt-4">
            <p className=" text-2xl text-white leading-9">
              Vybrana utilizes a pioneering green enzymatic process, free from
              harmful chemicals. This innovative approach minimizes
              environmental impact while maximizing efficiency. Our zero-waste
              optimized process ensures every part of the banana biomass is
              utilised, further reducing our environmental footprint.
            </p>
          </div>
          <div className="flex justify-center mt-5">
            <img className=" lg:h-[450px]  " src={vybranaimg4} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

const Vybranacomp5 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px]  items-center bg-white"
        // style={{
        //   backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${fiberbgimg3})`,
        //   backgroundSize: "100% 100%",
        //   backgroundAttachment: "fixed",
        // }}
      >
        <div>
          <div>
            <h1 className="text-black text-4xl max-sm:text-3xl font-semibold mt-[0px]">
              Green Attributes
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>
          <VybranaSlider1 />
        </div>
      </section>
    </>
  );
};

const Vybranacomp6 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(58, 58, 58, 0.7), rgba(58, 58, 58, 0.7)),url(${vybranabgImg5})`,
          backgroundSize: "100% 100%",
          // backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div>
            <h1 className="text-white text-4xl max-sm:text-3xl font-semibold mt-[0px]">
              The Power of Green Technology
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>

          <div className="flex justify-center mt-5">
            <img className="sm:h-[450px] rounded-xl" src={vybranaimg9} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

const Vybranacomp7 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px]  items-center bg-white"
        // style={{
        //   backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${fiberbgimg3})`,
        //   backgroundSize: "100% 100%",
        //   backgroundAttachment: "fixed",
        // }}
      >
        <div>
          <div>
            <h1 className="text-black text-4xl max-sm:text-3xl font-semibold mt-[0px]">
              Green Attributes
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>
          <VybranaSlider2 />
        </div>
      </section>
    </>
  );
};

export const VybranaSlider1 = () => {
  return (
    <>
      <div>
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex justify-center gap-5 mt-5">
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-6 max-sm:col-span-full sm:col-span-full lg:col-span-6 ">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={vybranaimg5}
                    alt=""
                  />
                </div>
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6  flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center gap-5 mt-5">
              <div className="grid grid-cols-12 gap-5">
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={vybranaimg6}
                    alt=""
                  />
                </div>
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6 flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center gap-5 mt-5">
              <div className="grid grid-cols-12 gap-5">
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={vybranaimg7}
                    alt=""
                  />
                </div>
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6 flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center gap-5 mt-5">
              <div className="grid grid-cols-12 gap-5">
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={vybranaimg8}
                    alt=""
                  />
                </div>
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6 flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="relative">
          <div>
            <div className="button-next absolute top-[-160px] hover:bg-yellow-400 z-20 transform -translate-y-1/2 right-4 bg-black bg-opacity-60 w-[45px] p-1 text-black-200 cursor-pointer">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-160px] hover:bg-yellow-400 z-20 transform -translate-y-1/2 left-4 bg-black bg-opacity-60 w-[45px] p-1 text-black-200 cursor-pointer">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const VybranaSlider2 = () => {
  return (
    <>
      <div>
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className=" gap-5 mt-5">
              <div className="grid grid-cols-12 gap-5">
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={vybranaimg10}
                    alt=""
                  />
                </div>
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6 flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-5">
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6 flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>

                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={vybranaimg12}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" gap-5 mt-5">
              <div className="grid grid-cols-12 gap-5">
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={vybranaimg11}
                    alt=""
                  />
                </div>
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6 flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-5">
                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6 flex justify-center items-center">
                  <div>
                    <h1 className="text-black text-3xl  ">
                      Farmer Freed From Biomass Disposal Burden
                    </h1>
                    <p className="text-xl font-semibold mt-5">
                      Reduce Farmer burden of post harvest agriwaste disposal &
                      supplement their income
                    </p>
                  </div>
                </div>

                <div className="max-sm:col-span-full sm:col-span-full lg:col-span-6">
                  <img
                    className="h-[290px] rounded-3xl "
                    src={vybranaimg13}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="relative">
          <div>
            <div className="button-next absolute top-[-350px] hover:bg-yellow-400 z-20 transform -translate-y-1/2 right-4 bg-black bg-opacity-60 w-[45px] p-1 text-black-200 cursor-pointer">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-350px] hover:bg-yellow-400 z-20 transform -translate-y-1/2 left-4 bg-black bg-opacity-60 w-[45px] p-1 text-black-200 cursor-pointer">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Vybranacomp8 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(58, 58, 58, 0.7), rgba(58, 58, 58, 0.7)),url(${vybranabgImg6})`,
          backgroundSize: "100% 100%",
          // backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div>
            <h1 className="text-white text-4xl max-sm:text-3xl font-semibold mt-[0px]">
              Water consumption of Banana Fibre vs Other Fibres
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>

          <div className="flex justify-center mt-5">
            <img className="" src={vybranaimg14} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

const Vybranacomp9 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[20px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(58, 58, 58, 0.1), rgba(58, 58, 58, 0.1)),url(${vybranabgImg11})`,
          backgroundSize: "100% 100%",
          // backgroundAttachment: "fixed",
        }}
      ></section>
    </>
  );
};
