import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { AboutCont } from "../Contact";
import mineral1 from "../../assets/images/nimmin/products/newproduct/minrals.jpg";

export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={mineral1} alt="" />
            </div>
          </SwiperSlide>
      
     
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const Minerals = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont/>
    
   
    </>
  );
};


const ContactCompo1 = () => {
    return (
      <>
        <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider/>
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold"> Bio Minerals </h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                Bio minerals derived from bananas focus on extracting essential minerals and nutrients from the fruit to create natural supplements and health products. For example, Bananas are rich in key minerals like potassium, magnesium, and manganese, which are vital for maintaining various bodily functions, including muscle contraction, nerve function, and bone health. The peel and pulp of bananas can be processed to concentrate these minerals, making them more bioavailable and easier to incorporate into dietary supplements. These bio minerals offer a natural and sustainable alternative to synthetic supplements, providing a way to support overall health and well-being through organic sources. By utilizing bananas as a mineral-rich resource, the development of bio minerals represents a step towards more eco-friendly and health-conscious nutrition.

                </li>
              </ul>
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>

    
      </section>
      </>
    );
  };

