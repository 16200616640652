import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import AgroSatvaimg4 from "../../assets/images/nimmin/agr/asset8.jpeg";

import liquidp1 from "../../assets/images/nimmin/products/liquidType3.7.png";
import liquidp2 from "../../assets/images/nimmin/products/liquidType3.8.png";

export const LiquidPoshan = () => {
  return (
    <>
      <AgroPurnaComp1 />
      {/* <AgroPurnaComp2 /> */}
    </>
  );
};

const AgroPurnaComp1 = () => {
  const handleLabelClick = () => {
    const pdfUrlpath = process.env.PUBLIC_URL + "/liquid_poshan_browser.pdf";
    downloadPdf(pdfUrlpath);
  };

  const downloadPdf = (url) => {
    const path = document.createElement("a");
    path.href = url;
    path.setAttribute("download", "liquid_poshan_browser.pdf");
    document.body.appendChild(path);
    path.click();
    document.body.removeChild(path);
  };
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider />
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold">
                  {" "}
                  Liquid Poshan{" "}
                </h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                  {" "}
                  Nimmin's Liquid Poshan is an advanced nutrient solution
                  designed to provide plants with essential minerals and
                  nutrients directly through foliar application or soil
                  drenching. This liquid fertilizer is crafted from organic
                  materials, ensuring that your plants receive a balanced diet
                  to promote healthy growth, vibrant foliage, and increased
                  yield.
                </li>

                <li className="py-2">
                  Contains a balanced mix of macro and micronutrients essential
                  for plant growth, including nitrogen, phosphorus, potassium,
                  calcium, and magnesium.
                </li>
                <li className="py-2">
                  Suitable for a wide range of plants, including vegetables,
                  fruits, flowers, and ornamental plants.
                </li>
              </ul>
              <div className="flex px-3  justify-center items-center text-center bg-green-700 py-1 w-[160px] text-white rounded-full">
                <div>
                  {" "}
                  <FaCloudDownloadAlt className="text-lg font-semibold" />
                </div>
                <div>
                  <button
                    className=" text-lg  font-semibol "
                    onClick={handleLabelClick}
                  >
                    Download Browser
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>

        <div className="mt-18">
          <Tabs />
        </div>
      </section>
    </>
  );
};

// ---------------
const Tabs = () => {
  const [activeTab, setActiveTab] = useState("WhyAgroSatva");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="w-full mx-auto  bg-gray-200 rounded-3xl px-3">
      <div className="flex justify-center border-b gap-5  w-full border-gray-300">
        <button
          className={`py-3 px-3 text-gray-700  text-lg font-semibold  ${
            activeTab === "WhyAgroSatva"
              ? "bg-white rounded-2xl "
              : "text-black   "
          }`}
          onClick={() => handleTabClick("WhyAgroSatva")}
        >
          Why Liquid Poshan?{" "}
        </button>
        <button
          className={`py-3 text-gray-700  text-lg font-semibold  px-3 ${
            activeTab === "DosageApplications"
              ? "bg-white rounded-2xl"
              : "text-gray-500"
          }`}
          onClick={() => handleTabClick("DosageApplications")}
        >
          Dosage & Applications
        </button>
      </div>
      <div className="mt-4">
        {activeTab === "WhyAgroSatva" && (
          <div>
            <ul>
              <li className="leading-10 sm:px-4">
                {" "}
                • Complete Nutrient Profile: Contains a balanced mix of macro
                and micronutrients essential for plant growth, including
                nitrogen, phosphorus, potassium, calcium, and magnesium.
              </li>
              <li className="leading-10 sm:px-4">
                • Fast Absorption: Being in liquid form, it is quickly absorbed
                by plants, ensuring rapid nutrient uptake and immediate results.
              </li>
              <li className="leading-10 sm:px-4">
                • Versatile Application: Suitable for a wide range of plants,
                including vegetables, fruits, flowers, and ornamental plants.
              </li>
              <li className="leading-10 sm:px-4">
                • Organic and Safe: Made from natural ingredients, it is safe
                for the environment, non-toxic to plants, and ideal for organic
                farming.{" "}
              </li>
              <li className="leading-10 sm:px-4">
                • Boosts Plant Immunity: Enhances plant resistance to diseases,
                pests, and environmental stressors, leading to healthier and
                more resilient crops.
              </li>{" "}
              <li className="leading-10 sm:px-4">
                {" "}
                •Improves Yield and Quality: Regular use can lead to improved
                crop yield, better fruit and flower quality, and overall plant
                vitality.
              </li>
            </ul>
          </div>
        )}
        {activeTab === "DosageApplications" && (
          <div className="w-full">
            <div className="m-5 sm:px-5">
              <ul className="mt-5">
                <p className=" leading-8">
                  {" "}
                  <span className="font-semibold">
                    For Foliar Application :{" "}
                  </span>
                  Mix 10-15 ml of Nimmin's Liquid Poshan with 1 liter of water.
                </p>
                <p>
                  {" "}
                  Spray evenly on the leaves until they are thoroughly coated,
                  preferably in the early morning or late afternoon to avoid
                  direct sunlight.
                </p>
                <p className=" leading-8">
                  Repeat every 10-15 days during the growing season.
                </p>
              </ul>

              <ul className="mt-5">
                <p className=" leading-8">
                  {" "}
                  <span className="font-semibold">For Soil Drenching : </span>
                  Mix 20-30 ml of Nimmin's Liquid Poshan with 1 liter of water.
                </p>
                <p>
                  Apply directly to the base of the plant, ensuring the soil
                  around the roots is well saturated.
                </p>
                <p className=" leading-8">
                  Use every 15-20 days to maintain optimal nutrient levels in
                  the soil.
                </p>
              </ul>

              <ul className="pb-5 mt-5">
                <p className=" leading-8">
                  {" "}
                  <span className="font-semibold"> For Hydroponics : </span>
                  Add 5-10 ml of Nimmin's Liquid Poshan per liter of water in
                  your hydroponic system.
                </p>
                <p>
                  {" "}
                  Monitor nutrient levels and adjust as needed based on plant
                  growth and health.
                </p>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;

export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={liquidp2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={liquidp1} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AgroPurnaSlider2 = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center">
              <iframe
                className="h-[380px]  w-[250px] sm:w-[200px]"
                src="https://www.youtube.com/embed/Uu6LBy05skA?si=enKfcVDJwhADPxdb"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DataBox = ({ grade, data }) => {
  return (
    <div className="bg-white shadow-md rounded-lg mb-4 w-full ">
      <h2 className="text-xl font-bold mb-4">Grade {grade}</h2>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="px-3 py-2 border-b-2 border-gray-300 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
              Element
            </th>
            <th className="px-3 py-2 border-b-2 border-gray-300 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([element, value]) => (
            <tr key={element} className="bg-white border-b">
              <td className="px-3 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                {element}
              </td>
              <td className="px-3 py-2 whitespace-no-wrap text-sm leading-5 text-gray-500">
                {value || "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const data1 = [
  {
    grade: 2,
    Zn: "3.00%",
    Fe: "2.50%",
    Cu: "1.00%",
    Mn: "1.00%",
    Bo: "0.50%",
    Mo: "0.10%",
  },
  { grade: 9, Zn: "3.00%", Bo: "0.50%" },
  { grade: 10, Zn: "5.00%", Fe: "2.50%", Cu: "0%", Bo: "0.50%", Mo: "0.10%" },
];

const data2 = [
  {
    grade: 5,
    Zn: "2.00%",
    Fe: "3.00%",
    Cu: "0.50%",
    Mn: "1.50%",
    Bo: "0.25%",
    Mo: "0.05%",
  },
  {
    grade: 7,
    Zn: "4.00%",
    Fe: "1.50%",
    Cu: "0.75%",
    Mn: "1.00%",
    Bo: "0.50%",
    Mo: "0.15%",
  },
  {
    grade: 12,
    Zn: "3.50%",
    Fe: "2.00%",
    Cu: "0.25%",
    Mn: "1.25%",
    Bo: "0.30%",
    Mo: "0.08%",
  },
];

const DataContainer = ({ data }) => {
  return (
    <div className="container mx-auto px-3 py-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
      {data.map((item, index) => (
        <DataBox key={index} grade={item.grade} data={item} />
      ))}
    </div>
  );
};
