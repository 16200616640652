import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";
import img1 from "../../assets/images/nimmin//BIO-FIBER2.jpg"
import img2 from "../../assets/images/nimmin/bioagri.png";
import img3 from "../../assets/images/nimmin/BIO-construction.jpg";
import img4 from "../../assets/images/nimmin/BIO-FIBER 7.jpg";
import img5 from "../../assets/images/nimmin/bio-nutiricianas.jpg";
import img6 from "../../assets/images/nimmin/medical.jpg";

export const slides = [
  {
    title:`Welcome to Nimmin  `,
      title2:`Agricultural waste into valuable Resources that support sustainable living Crafting sustainable solutions for a greener and healthier planet`,
    subtitle:"Crafting sustainable solutions for a greener and healthier planet",
    heading:"BioFiber",
    titleheading:`Boosting BioFiber`,
    image: img1,
  },
  {
    title:`Welcome to Nimmin  `,
      title2:`Agricultural waste into valuable Resources that support sustainable living Crafting sustainable solutions for a greener and healthier planet`,
    subtitle:"Crafting sustainable solutions for a greener and healthier planet",
    heading:"BioAgriculture",
    titleheading:`Boosting Bio Agriculture`,
    image: img2,
  },
  {
    title:`Welcome to Nimmin  `,
      title2:`Agricultural waste into valuable Resources that support sustainable living Crafting sustainable solutions for a greener and healthier planet`,
    subtitle:"Crafting sustainable solutions for a greener and healthier planet",
    heading:"BioNutiricianas",
    titleheading:`Boosting Bio Nutiricianas`,
    image: img5,
  },
  {
    title:`Welcome to Nimmin  `,
      title2:`Agricultural waste into valuable Resources that support sustainable living Crafting sustainable solutions for a greener and healthier planet`,
    subtitle:"Crafting sustainable solutions for a greener and healthier planet",
    heading:"Bio-food",
    titleheading:`Boosting  Agriculture originally `,
    image: img4,
  },
  {
    title:`Welcome to Nimmin  `,
      title2:`Agricultural waste into valuable Resources that support sustainable living Crafting sustainable solutions for a greener and healthier planet`,
    subtitle:"Crafting sustainable solutions for a greener and healthier planet",
    heading:"BioConstruction",
    titleheading:`Boosting Bio Construction`,
    image: img3,
  },
  
  {
    title:`Welcome to Nimmin  `,
      title2:`Agricultural waste into valuable Resources that support sustainable living Crafting sustainable solutions for a greener and healthier planet`,
    subtitle:"Crafting sustainable solutions for a greener and healthier planet",
    heading:"BioMedicinal",
    titleheading:`Boosting BioMedicinal Products`,
    image: img6,
  },


];

export const Hero = () => {
  return <HeroSlider slides={slides} />;
};



export const HeroSlider = ({ slides }) => {
  const swiperRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };

  const handleClickPaginationBullet = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <div className="bg-white relative">
      <Swiper
        ref={swiperRef}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        effect={"fade"}
        onSlideChange={handleSlideChange}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
          type: "custom",
          renderCustom: (swiper, current, total) => {
            setCurrentIndex(current - 1); 
            return ''; 
          }
        }}
        navigation={{
          nextEl: ".button-next",
          prevEl: ".button-prev",
        }}
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        className="mySwiper"
      >
        {slides &&
          slides.map((slide, index) => (
            <SwiperSlide key={index}>
            <div className="relative w-full h-screen flex items-center justify-center overflow-hidden">
              <img className="absolute inset-0 w-full h-full object-cover" src={slide.image} alt="" />
              <div className="absolute inset-0 bg-black opacity-50"></div>
              <div className="relative text-center lg:text-left lg:px-20 px-4">
                <h1 className="text-4xl sm:text-4xl md:text-5xl font-bold text-white mb-2">{slide.title}</h1>
                <h2 className="text-xl sm:text-2xl md:text-3xl mt-5 font-semibold text-white mb-4">{slide.title2}</h2>
                <p className={`text-lg md:text-xl font-semibold text-white ${index === currentIndex ? 'block' : 'hidden'}`}>{slide.subtitle}</p>
                <hr className="mt-14"></hr>


              </div>

              

            </div>
          </SwiperSlide>
          ))}
      </Swiper>


      {/* Custom Pagination */}
      <div className="absolute max-sm:bottom-[190px] bottom-[130px] left-0 right-0 z-10 ">
        <div className="flex justify-center  relative">
          {slides &&
            slides.map((slide, index) => (
              <div
                key={index}
                className={`relative flex-1 text-center cursor-pointer ${index === currentIndex ? 'text-blue-500' : 'text-gray-500'} ${window.innerWidth < 1100 && index !== currentIndex ? 'hidden' : 'block w-full'}`}
                onClick={() => handleClickPaginationBullet(index)}
              >
                {/* <div className={`swiper-pagination-bullet w-3 h-3 rounded-full ${index === currentIndex ? 'bg-red-800' : 'bg-gray-300'} ${index === currentIndex ? 'scale-125 ' : 'scale-100'} transition-transform`}></div> */}
                <div className={`absolute w-[150px] left-1/2 transform -translate-x-1/2 top-8 p-2 rounded shadow-lg ${index === currentIndex ? 'bg-red-100 bg-opacity-0 z-50' : 'bg-white bg-opacity-0 '} ${index === currentIndex ? 'translate-y-[-10px]' : 'translate-y-0'} transition-transform`}>
                  <h2 className={`text-xl text-white font-bold ${index === currentIndex ? 'text-blue-500 border-b-[1px] border-yellow-500 ' : 'text-gray-800'} `}>{slide.heading}</h2>
                  <p className={`text-sm mt-1 text-white ${index === currentIndex ? 'text-blue-400' : 'text-gray-600'} ${index === currentIndex ? 'block' : 'hidden'}`}>{slide.titleheading}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};








