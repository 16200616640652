import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";

import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { AboutCont } from "../Contact";
import pickles from "../../assets/images/nimmin/products/newproduct/textile.jpg";

export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={pickles} alt="" />
            </div>
          </SwiperSlide>
          
     
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Pickles = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont/>
    
   
    </>
  );
};


const ContactCompo1 = () => {
    return (
      <>
       <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider/>
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold">Bio-Food like Pickles</h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                Flavorful pickles made from organic parts, offering a unique taste. Banana pickles are an innovative bio-food that transforms bananas into a tangy, flavorful condiment. Unlike traditional pickles made from cucumbers, banana pickles offer a unique blend of sweet and sour flavors, combining the natural sweetness of the fruit with the zest of vinegar and spices. These pickles are rich in potassium, vitamin B6, and dietary fiber, making them a nutritious addition to meals. The pickling process enhances the banana's shelf life, allowing for long-term storage and reducing food waste by utilizing bananas that might otherwise spoil. Banana pickles can be enjoyed as a side dish, added to salads, or used as a topping for sandwiches, providing a creative, sustainable alternative to conventional pickled vegetables that aligns with eco-friendly and health-conscious eating.
                </li>
           
              </ul>
          
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>
      </section>
      </>
    );
  };

