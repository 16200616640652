import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Bioagricompimg1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import BioagricompImg2 from "../../assets/images/nimmin/agr/asset19.jpeg";


import productImg2 from "../../assets/images/nimmin/products/vormiType1_4.png";
import productImg1 from "../../assets/images/nimmin/products/2.jpg";
import productImg3 from "../../assets/images/nimmin/products/liquidType3.8.png";
import productImg4 from "../../assets/images/nimmin/products/liquidType3.7.png";
import productImg5 from "../../assets/images/nimmin/products/broomType2-7.png";
import productImg6 from "../../assets/images/nimmin/products/broomType2-9.png";

export const BioAGri = () => {
  return (
    <>
      <BioagriComp1 />
      <Bioagricomp2 />
    </>
  );
};

const BioagriComp1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${Bioagricompimg1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[120px]">
          <div className="container grid grid-cols-12 bg-black bg-opacity-40 rounded-3xl">
            <div className="  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {" "}
                <h1 className="text-white text-5xl  max-sm:text-4xl font-semibold">
                  BioAgri
                </h1>
                <hr className=" bg-yellow-500 p-1 w-[0px] my-1" />
              </div>
              <h1 className="text-3xl max-sm:text-2xl font-semibold py-3 text-white">
                Innovative Solutions for Sustainable Agriculture{" "}
              </h1>
              <p className="text-base  font-semibold text-white leading-7 mt-5">
                Nimmin's Bio Agri division is dedicated to revolutionizing
                agriculture through sustainable and eco-friendly solutions. By
                transforming organic waste into valuable agricultural products,
                Nimmin's Bio Agri offers a range of natural fertilizers,
                nutrient sprays and vermicompost. These products are designed to
                enhance soil fertility, boost plant health, and support
                sustainable farming practices, all while reducing environmental
                impact. Nimmin’s Bio Agri is committed to helping farmers
                achieve higher yields and healthier crops, contributing to a
                greener and more resilient agricultural future.
              </p>
            </div>
          </div>

          <hr className="w-full mt-[60px] " />
        </div>
      </section>
    </>
  );
};

// ---------------
// component 2

const Bioagricomp2 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${BioagricompImg2})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div>
            <h1 className="text-white text-5xl max-sm:text-4xl font-bold mt-[0px]">
              Our Products
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>
          <ProductSlider1 />
        </div>
      </section>
    </>
  );
};

export const ProductSlider1 = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[320px] rounded-xl" src={productImg2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex  justify-center ">
              <img className="h-[320px] rounded-xl" src={productImg1} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[320px] rounded-xl" src={productImg3} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex  justify-center ">
              <img className="h-[320px] rounded-xl" src={productImg4} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[320px] rounded-xl" src={productImg5} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[320px] rounded-xl" src={productImg6} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


