import React from "react";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Fiber img
import fiberbgimg1 from "../../assets/images/nimmin/Fiber/asset24.jpeg";

import fiberbgimg4 from "../../assets/images/nimmin/Fiber/asset29.jpeg";
//
import fiberimg1 from "../../assets/images/nimmin/BIO-FIBER 3.jpg";
import fiberimg2 from "../../assets/images/nimmin/BIO-FIBER.jpg";

import fiberimg5 from "../../assets/images/nimmin/Fiber/asset 7.png";
import fiberimg6 from "../../assets/images/nimmin/Fiber/asset 8.png";
import fiberimg7 from "../../assets/images/nimmin/Fiber/asset 9.png";
import fiberimg8 from "../../assets/images/nimmin/Fiber/asset 10.png";
import fiberimg9 from "../../assets/images/nimmin/Fiber/asset 11.png";
import fiberimg10 from "../../assets/images/nimmin/Fiber/asset 12.png";
import fiberimg15 from "../../assets/images/nimmin/Fiber/asset 20.png";

import { FaCloudDownloadAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export const Fiber = () => {
  return (
    <>
      <FiberComp1 />
      <Fibercomp2 />
      {/* <Fibercomp3 /> */}
      <Fibercomp4 />
      {/* <Fibercomp5 /> */}
      <Fibercomp6 />
      {/* <Fibercomp7 /> */}
    </>
  );
};

const FiberComp1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${fiberbgimg1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[120px] max-sm:mt-[160px] sm:px-[80px]">
          <div className="container grid grid-cols-12 bg-black bg-opacity-40 rounded-3xl">
            <div className="  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {" "}
                <h1 className="text-white text-5xl  max-sm:text-4xl font-semibold">
                  Bio Fibre
                </h1>
                <hr className=" bg-yellow-500 p-1 w-[0px] my-1" />
              </div>
              <h1 className="text-3xl font-semibold py-3 text-white">
                Sustainable fashion for greener planet!{" "}
              </h1>
              <p className="text-base font-semibold text-white leading-7 mt-5">
              Nimmin's Bio Fibre division focuses on creating sustainable materials by transforming organic waste into high-quality fibers. These eco-friendly fibers are used in various applications, including textiles, packaging, paper, and handicrafts. Nimmin’s Bio Fibre products offer a sustainable alternative to traditional materials, helping to reduce reliance on non-renewable resources and minimize environmental impact. By turning organic waste into valuable resources, Nimmin’s Bio Fibre division supports innovation in sustainable production, offering durable, versatile, and environmentally responsible solutions for various industries.

              </p>
            </div>
          </div>

          <hr className="w-full mt-[60px] " />
        </div>
      </section>
    </>
  );
};

// ---------------
// component 2

const Fibercomp2 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        //   style={{
        //     backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgribusinesscompImg2})`,
        //     backgroundSize: "100% 100%",
        //     backgroundAttachment: "fixed",
        //   }}
      >
        <div>
          <div>
            <h1 className="text-black text-5xl max-sm:text-3xl font-bold mt-[0px]">
              Sustainable Cellulosic Fibre Innovation!
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>
          <p className="mt-5 leading-7 text-lg text-gray-700 ">
            Textile industry has an urgent mandate to move from Petro derivative
            – synthetics to sustainable raw material. Garments made from
            cellulose fibre are breathable, soft and most suited for human skin
            and they are bio degradable with negligible end of life impact.
            There is numerous fibre yielding plants which offer unique
            properties but these fibres have challenges on spinnability. Also
            sizeable amount of post harvest crop waste , usually plant stems
            (bast) which is rich in cellulose and on other hand disposal of this
            agriwaste which poses huge cost burden and environmental concern.
            Gencrest analysed that there is an urgent need & unique opportunity
            of developing efficient and sustainable process for converting such
            cellulose rich biomass into spinnable textile grade fibres to offer
            sustainable raw material options to Apparel industry.
          </p>

          <div className="mt-5">
            <img  className="sm:h-[340px] w-full rounded-md" src={fiberimg1} alt="" />
            <img className="mt-5 sm:h-[340px] w-full rounded-md" src={fiberimg2} alt="" />
          </div>

          <div className="flex justify-center mt-5">
            <div className="flex px-3  justify-center items-center text-center bg-green-700 py-1 w-[160px] text-white rounded-full">
              <div>
                {" "}
                <FaCloudDownloadAlt className="text-lg font-semibold" />
              </div>
              <div className="">
                <button className=" text-lg   font-semibol ">
                  Download Browser
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

//



const Fibercomp4 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] sm:py-[50px] max-sm:px-[20px] max-sm:py-[50px] bg-gray-100  items-center"
        //   style={{
        //     backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${fiberbgimg4})`,
        //     backgroundSize: "100% 100%",
        //     backgroundAttachment: "fixed",
        //   }}
      >
        <div>
          <div>
            <h1 className="text-black text-5xl max-sm:text-3xl font-bold mt-[0px]">
              Our Product Segment
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-2" />
          </div>
          <p className="mt-5  text-2xl font-semibold leading-10 text-black ">
            This innovative approach allows us to create comfortable, stylish
            clothing without compromising the environment.
          </p>

          <div className="grid grid-cols-12 gap-6 sm:px-[80px] mt-5">
            <div className="col-span-4 ">
              <img
                className="h-[200px] rounded-lg hover:scale-90 transition duration-700"
                src={fiberimg5}
                alt=""
              />
            </div>
            <div className="col-span-4">
              <img
                className="h-[200px] rounded-lg hover:scale-90 transition duration-700"
                src={fiberimg6}
                alt=""
              />
            </div>
            <div className="col-span-4">
              <img
                className="h-[200px] rounded-lg hover:scale-90 transition duration-700"
                src={fiberimg7}
                alt=""
              />
            </div>
            <div className="col-span-4">
              <img
                className="h-[200px] rounded-lg hover:scale-90 transition duration-700"
                src={fiberimg8}
                alt=""
              />
            </div>
            <div className="col-span-4">
              <img
                className="h-[200px] rounded-lg hover:scale-90 transition duration-700"
                src={fiberimg9}
                alt=""
              />
            </div>
            <div className="col-span-4">
              <img
                className="h-[200px] rounded-lg hover:scale-90 transition duration-700"
                src={fiberimg10}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};




const Fibercomp6 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px] py-[50px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${fiberbgimg4})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div>
          <div className="flex max-sm:flex-col sm:flex-col lg:flex-row justify-between sm:px-[80px]">
            <div className="col lg:w-1/2 max-sm:w-full sm:w-full items-center ">
              <h1 className="text-3xl leading-10 text-black font-semibold ">
                Gencrest BioProducts Pvt Ltd is thrilled to announce that we
                have been selected as one of the 10 new innovators for Fashion
                for Good's prestigious 2024 innovation programme!
              </h1>
              <div className="mt-3">
                <NavLink className="text-blue-600 text-2xl font-semibold ">
                  Read more{" "}
                </NavLink>
              </div>
            </div>
            <div className=" lg:w-1/2  max-sm:w-full">
              <img src={fiberimg15} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};



