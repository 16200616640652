import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { AboutCont } from "../Contact";

import backery1 from "../../assets/images/nimmin/products/newproduct/bakery1.jpg";

export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={backery1} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const BakeryProducts = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont />
    </>
  );
};

const ContactCompo1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider />
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold">
                  {" "}
                  Bio Bakery Products{" "}
                </h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                  {" "}
                  Baked goods such as bread, cakes, and pastries using
                  eco-friendly ingredients. Bakery products like banana-based
                  bakery products offer a delicious and nutritious alternative
                  to traditional baked goods. Incorporating bananas into items
                  like bread, muffins, cakes, and cookies enhances their flavor
                  while boosting their nutritional profile. Bananas add natural
                  sweetness, reducing the need for added sugars, and contribute
                  moisture, which can replace fats like butter or oil in
                  recipes. This results in healthier, lower-calorie baked goods.
                  Bananas are also rich in potassium, vitamin B6, and fiber,
                  making these bakery products more nutritious. The use of
                  bananas in baking aligns with sustainable practices by
                  utilizing overripe bananas that might otherwise go to waste.
                  This approach not only reduces food waste but also creates
                  bakery products that are both tasty and health-conscious,
                  appealing to those seeking natural, wholesome treats.
                </li>
              </ul>
              <div className="flex px-3  justify-center items-center text-center bg-green-700 py-1 w-[160px] text-white rounded-full">
                <div>
                  {" "}
                  <FaCloudDownloadAlt className="text-lg font-semibold" />
                </div>
                <div>
                  <button className=" text-lg  font-semibol ">
                    Download Browser
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>
      </section>
    </>
  );
};
