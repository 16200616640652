import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { AboutCont } from "../Contact";
import flour from "../../assets/images/nimmin/products/newproduct/flour.jpg";


export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={flour} alt="" />
            </div>
          </SwiperSlide>
         
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Flour = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont />
    </>
  );
};

const ContactCompo1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider />
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold">
                  {" "}
                  Bio-Food like Flour{" "}
                </h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                  Used in baking, cooking, and as a gluten-free alternative.
                  Bio-foods like banana flour are part of an innovative approach
                  to creating sustainable, nutritious alternatives to
                  traditional staples. Banana flour is made by drying and
                  grinding green bananas, resulting in a gluten-free,
                  nutrient-rich powder. It is high in resistant starch, which
                  acts as a prebiotic, promoting gut health and improving
                  digestion. Additionally, banana flour is a good source of
                  potassium, magnesium, and dietary fiber, making it a healthful
                  substitute for wheat flour in various recipes.
                </li>
                <li className="py-2">
                  Because it is produced from underripe bananas that might
                  otherwise go to waste, banana flour contributes to reducing
                  food waste and supports sustainable agriculture. This
                  versatile flour can be used in baking, cooking, and thickening
                  sauces, offering a natural, gluten-free option that enhances
                  both nutrition and sustainability in food production.
                </li>
              </ul>
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>
      </section>
    </>
  );
};
