import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";  
import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { AboutCont } from "../Contact";
import texttile1 from "../../assets/images/nimmin/products/newproduct/textile.jpg";

export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={texttile1} alt="" />
            </div>
          </SwiperSlide>
        
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Textiles = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont />
    </>
  );
};

const ContactCompo1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider />
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold">
                  Textiles
                </h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                  {" "}
                  Bio fibre textiles are an innovative and sustainable material
                  derived from the pseudo-stems like banana plants. These fibers
                  are extracted from the stem's inner layers, a byproduct of
                  banana cultivation, making them an eco-friendly alternative to
                  conventional textiles. The process of extracting banana fibers
                  is environmentally friendly, as it utilizes waste material
                  that would otherwise be discarded. Banana fibers are strong,
                  durable, and biodegradable, with properties similar to bamboo
                  and jute, making them suitable for various textile
                  applications, including clothing, home furnishings, and
                  industrial textiles.
                </li>

                <li className="py-2">
                  In the textile industry, banana bio fibers are increasingly
                  being explored for their potential to create sustainable
                  fabrics. These fibers are naturally moisture-wicking,
                  breathable, and lightweight, offering comfort and versatility
                  in fabric production. Additionally, the cultivation of banana
                  plants for fiber extraction supports sustainable agriculture
                  and provides economic opportunities for farmers in
                  banana-producing regions. As the demand for eco-friendly
                  textiles grows, banana bio fibre textiles are poised to play a
                  significant role in the future of sustainable fashion.
                </li>
              </ul>
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>
      </section>
    </>
  );
};

