import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { Hero } from "../../Components/hero/Hero";
import bgcompo1 from "../../assets/images/nimmin/asset15.jpeg";

import { FaArrowRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

import init2_1 from "../../assets/images/nimmin/init2_1.png";
import init2_2 from "../../assets/images/nimmin/init2_2.png";
import init2_3 from "../../assets/images/nimmin/init2_3.png";
import init2_4 from "../../assets/images/nimmin/init2_4.png";
import init2_5 from "../../assets/images/nimmin/init2_5.png";
import init2_6 from "../../assets/images/nimmin/init2_6.png";

export const Homepagee = () => {
  return (
    <>
      <Hero />
      <HomeCompo1 />
      <HomeCompo3 />
      <HomeCompo5 />
    </>
  );
};

const HomeCompo1 = () => {
  return (
    <>
      <div
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px] flex items-center justify-center h-[100vh]"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(21,40,59, 0.1), rgba(21,40,59, 0.1)),url(${bgcompo1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="container mx-auto bg-black p-4 bg-opacity-40 ">
          <div className=" grid grid-cols-12 p-8  max-sm:gap-5 sm:gap-5 lg:gap-0">
            <div className="lg:col-span-8 sm:col-span-12 max-sm:col-span-12 flex gap-3 ">
              <span className="h-[40px] bg-white mt-3 p-[1px]"></span>
              <h1 className="text-[32px] max-sm:text-[26px] font-thin  lg:pr-[240px] text-white max-sm:leading-[40px] leading-[45px] open-sans  ">
                {" "}
                The future depends on how efficiently we transform into circular
                businesses. <br />
                Our survival depends on it.{" "}
              </h1>
            </div>
            <div className="lg:col-span-4 sm:col-span-12  flex gap-3">
              <span className="h-[40px] bg-white mt-3 p-[1px]"></span>
              <h1 className="text-lg font-semibold   text-white  leading-[30px] open-sans ">
                Ambrish Anand <br />
                Chairman
              </h1>
            </div>
          </div>
          <hr />

          <div className="flex gap-5">
            <h1 className="text-xl font-semibold  ml-5 py-5  text-white  leading-[30px] open-sans">
              Prakriti Nahi! Pravriti Badlen!
            </h1>
            <NavLink to="">
              {" "}
              <p className="text-xl font-semibold border-2 rounded-full mt-5 outline-4 p-1 items-center  text-white  leading-[30px] open-sans">
                <FaArrowRight />
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

const HomeCompo3 = () => {
  return (
    <>
      <section
        className="HomeCompo1 max-sm:py-10 sm:p-[100px]  max-sm:px-[20px]  flex justify-center "
        style={{
          background: "#f4b000",
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="">
          <div className="text-center text-white">
            <h1 className="text-5xl font-semibold  mb-4 ">Our Mission</h1>
            <p className="text-xl leading-[30px] font-medium mt-5 ">
              At Nimmin, we are dedicated to crafting sustainable solutions by
              transforming agricultural waste into valuable resources. Our focus
              is on utilizing discarded byproducts to create innovative and
              eco-friendly products. We believe in the power of sustainability
              to shape a greener and healthier planet for future generations.
            </p>
            <p className="text-xl leading-[30px] font-medium mt-5">
              Nimmin “believes in crafting sustainable solution” with priorities
              of greener and healthier planet. We bring a essence of
              transforming agricultural waste into valuable resource. With our
              commitment to environmental stewardship, we harness discarded
              banana by product to create innovative solution.
            </p>
            <p className="text-xl leading-[30px] mt-5 font-medium ">
              We introduce ourselves as an organization where every person,
              product and process exists to accentuate sustainable living. In
              other words, we make products and services which are “Basic
              Needs”, “Healthier”, “Safer”, “Smarter”, “Desirable” and most of
              all “ Highly Sustainable” for future generations.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};



export const slidesData2 = [
  {
    image: "",
    title: "",
    description: "",
  },
];

export const HomeCompo5 = ({ slides2 = InitSlide4Data }) => {
  return (
    <>
      <div className="container mx-auto justify-center py-[40px] max-sm:py-[90px] max-md:py-[90px] max-lg:py-[90px] px-8 ">
        <div>
          <Swiper
            // slidesPerView={3}
            spaceBetween={5}
            loop={true}
            loopFillGroupWithBlank={true}
            centeredSlides={true}
            autoplay={{
              delay: 2200,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              667: {
                slidesPerView: 2,
              },
              991: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
            className="mySwiper"
            // onSlideChangeTransitionEnd={(swiper) => handleSlideChangeTransitionEnd(swiper)}
          >
            {slides2.map((slide, index) => (
              <SwiperSlide
                key={index}
                className="rounded max-sm:rounded-xl py-6 min-h-[400px] max-h-28   bg-opacity-30	"
              >
                <div className="card container items-center justify-center">
                  <div className="items-center text-center text-black">
                    <div className="m-auto items-center flex justify-center">
                      <img
                        className="h-[200px] w-[200px] object-fill rounded-full mb-2"
                        src={slide.image}
                        alt={`Slide ${index + 1}`}
                      />
                    </div>

                    <div className="container  top-[90px]  items-center justify-center ">
                      <h1 className="text-xl text-gray-600">{slide.title}</h1>
                      <h1 className="text-sm font-thin text-gray-600 mt-2">
                        {slide.description}
                      </h1>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export const InitSlide4Data = [
  {
    image: init2_1,
    title: "Environmental Benfits",
    description:
      "It helps conserving natural resources and protects the air, water & Land Ecosystem from hazardus chemicals and hence pollution ",
  },
  {
    image: init2_2,
    title: "Environmental Benfits",
    description:
      "It does not produce GHG (Green House Gases) unlike chemical and industrial products of similar category. ",
  },
  {
    image: init2_3,
    title: "Biological Benefits",
    description:
      "It provides complete nutrition with essenntial groth promoters for quick & healthy groth of the plants",
  },
  {
    image: init2_4,
    title: "Bilogical Benfits",
    description:
      "Crops, Fruits & Vegetables grown using IWT have high nutritional value and  rich in oxidants.",
  },
  {
    image: init2_5,
    title: "Physical & Chemical Benfits",
    description:
      "Regular use of IWT imports better soil quality, texture, porosity, water holding capacity,",
  },
  {
    image: init2_6,
    title: "Physical & Chemical Benfits",
    description:
      "It also improves PH, electrical conductivity and organic matter content for better crop yield.",
  },
];
