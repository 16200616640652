import React from "react";
import{ Navbar } from "../navbar/Navbar";
import MainR from "../Route";
import Footer from "../Footer/Footer";
export const HomePage = () =>{
  return(
    <>
  <div className="">
      <Navbar />
      <main className="p-4  relative top-6 h-[0px]">
      </main>
    </div>
    <div className=" relative top-[-32px] ">
    <MainR/>
    </div>
    <Footer/>
    </>
  )


}