import React from "react";
import peoplecomp1 from "../assets/images/nimmin/people/asset24.jpeg";

import gal_1 from "../assets/images/nimmin/gallery/gal11.png";
import gal_2 from "../assets/images/nimmin/gallery/gal2.png";
import gal_3 from "../assets/images/nimmin/gallery/gal3.png";
import gal_4 from "../assets/images/nimmin/gallery/gal4.png";
import gal_5 from "../assets/images/nimmin/gallery/gal5.png";
import gal_6 from "../assets/images/nimmin/gallery/gal6.png";
import gal_7 from "../assets/images/nimmin/gallery/gal7.png";
import gal_8 from "../assets/images/nimmin/gallery/gal8.png";
import gal_9 from "../assets/images/nimmin/gallery/gal9.png";
import gal_10 from "../assets/images/nimmin/gallery/gal10.png";
import gal_11 from "../assets/images/nimmin/gallery/gal11.png";

export const People = () => {
  return (
    <>
      <PeopleCompo1 />
      <GalleryContent />
    </>
  );
};

const PeopleCompo1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center  h-[100vh]"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)),url(${peoplecomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="max-sm:mt-[100px] mt-[25vh]">
          <h1 className="text-5xl text-start max-sm:text-3xl font-semibold text-white leading-[60px] tracking-wide	 ">
            Keep your eyes <br /> on the stars and your stars and your <br />{" "}
            feet on the ground.
          </h1>

          <hr className="w-full mt-[100px] " />
          <h1 className="mt-4 max-sm:text-base text-xl font-semibold tracking-widest open-sans  text-white">
            In the quest for a greener future, we don't just seek solutions;{" "}
            <br /> we ignite a revolution where nature and technology harmonize
            to create a better world.
          </h1>
        </div>
      </section>
    </>
  );
};

export const GalleryContent = () => {
  const handleButtonClick = () => {
    // Your button click logic here
    console.log("Button Clicked!");
  };

  return (
    <section className="container mx-auto px-5">
      <div className="py-5 ">
        <h1 className=" text-3xl font-bold  text-gray-600 ">Vermicompost</h1>
        <p className="w-[50px] border-yellow-500 border-b-4 mt-2  "></p>
      </div>

      <div className=" grid grid-cols-1 max-sm:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8  mx-auto mt-5  ">
        {InitCard1Data.map((card) => (
          <div
            key={card.id}
            className="col-span-1 cursor-pointer mb-5 shadow-lg"
          >
            <div className="flex flex-col items-start w-full p-4">
              <div className="overflow-hidden">
                <img
                  src={card.imageSrc}
                  alt="Card"
                  className=" max-sm:w-[100vw] min-h-[250px] max-h-[250px] w-[100vw]   mb-4 sm:w-[100vw]  3-[200px] transition duration-300 hover:scale-110 rounded-md"
                />
              </div>
              <h1
                onClick={handleButtonClick}
                className="flex  cursor-pointer text-xl font-semibold mb-2"
              >
                {card.title}
              </h1>
              <p
                onClick={handleButtonClick}
                className="flex items-center  text-sm  text-gray-500"
              >
                {card.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export const InitCard1Data = [
  {
    key: 1,
    imageSrc: gal_1,
    title: "IWT",
    desc: "An universal formulae to meet your vegetation needs",
  },
  {
    key: 1,
    imageSrc: gal_2,
    title: "Ingredients",
    desc: "Organic Wastes,Earthworms,Sustainable Future",
  },
  {
    key: 1,
    imageSrc: gal_8,
    title: "Why Choosev IWT",
    desc: "Chemicals Free & Universal,High Yield, More Profit,Promotes Sustainable Living",
  },
  {
    key: 1,
    imageSrc: gal_7,
    title: "",
    desc: "Chemicals Free & Universal,High Yield, More Profit,Promotes Sustainable Living",
  },

  {
    key: 1,
    imageSrc: gal_3,
    title: "Nutritional Components",
    desc: "Macro & Micronutrients,Polyphenols & antioxidant,Plant Micro Fibers",
  },
  {
    key: 1,
    imageSrc: gal_4,
    title: "Organic Waste (Solid & Liquid)",
    desc: "Agricultural Waste mixed with Cow Dung in a specific proportion to meet all nutritional requirements for the plant.",
  },
  {
    key: 1,
    imageSrc: gal_5,
    title: "Macro & Micro Nutrients",
    desc: "Macronutrients are required in huge amounts by plants and includes Carbon (C),Hydrogen (H), Oxygen (O), Nitrogen (N), Phosphorous (P), Potassium (K), Calcium (Ca),Sulfur (S), and Magnesium (Mg)",
  },
  {
    key: 1,
    imageSrc: gal_6,
    title: "Benefits",
    desc: "Benefits,Physical & Chemical,Biological,Environmental",
  },

  {
    key: 1,
    imageSrc: gal_9,
    title: "Applications",
    desc: "Applications,In Agriculture & Farming,In Nurseries,In Gardening,In Aquaculture",
  },
  {
    key: 1,
    imageSrc: gal_10,
    title: "Applications",
    desc: "Use it to grow organic yield, Use it to grow organic yield Helps in improving soil texture,aeration and increase water retention capacity.",
  },
  {
    key: 1,
    imageSrc: gal_11,
    title: "Environmental Benefits",
    desc: "m Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum hasIt helps conserving natural resources and protects the Air, Water Land Ecosystem from hazardous chemicals and hence pollution, It does not produce GHG (Green House Gases) unlike chemical and industrial products of similar category.",
  },
];
