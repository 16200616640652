import React, { useEffect } from "react";

import aboutcomp1 from "../../assets/images/nimmin/about/asset6.jpeg";
import aboutcomp2 from "../../assets/images/nimmin/about/asset7.jpeg";
import aboutcomp3 from "../../assets/images/nimmin/about/asset8.jpeg";

import aboutcomp4 from "../../assets/images/nimmin/about/asset9.jpeg";

import complogo1 from "../../assets/images/nimmin/gyprlog1.png";
import complogo2 from "../../assets/images/nimmin/nimlogo2.png";
import complogo3 from "../../assets/images/nimmin/nimlogo3.png";

import { motion, useScroll, useTransform } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";

export const About = () => {
  return (
    <>
      <AboutCompo1 />
      <AboutCompo2 />
      <AboutCompo3 />
      <AboutCompo4 />
      <AboutUs />
    </>
  );
};

const AboutCompo1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center  h-[100vh]"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)),url(${aboutcomp1})`,
          // backgroundSize: "100% 100%",
          // backgroundAttachment: "fixed",
        }}
      >
        <div className="max-sm:mt-[100px] mt-[25vh]">
          <h1 className="text-4xl max-sm:text-2xl text-start font-semibold text-white leading-[60px] tracking-wide	 ">
            Transform discarded <br /> byproducts healthier, safer, smarter,
            desirable and most importantly, highly sustainable for future
            generations.
          </h1>

          <h1 className="text-4xl max-sm:text-2xl text-start font-semibold text-white leading-[60px] tracking-wide">
            Sustainable and innovative solutions for a healthier planet.{" "}
          </h1>

          <hr className="w-full mt-[100px] " />
          <h1 className="mt-4 text-xl max-sm:text-base font-semibold tracking-widest open-sans  text-white">
            In the quest for a greener future, we don't just seek solutions;{" "}
            <br /> we ignite a revolution where nature and technology harmonize
            to create a better world.
          </h1>
        </div>
      </section>
    </>
  );
};

const AboutCompo2 = () => {
  // Hook to get the scroll position
  const { scrollY } = useScroll();

  // Transform scroll position to animate the section
  const x = useTransform(scrollY, [0, 600], ["-100%", "0%"]);
  const opacity = useTransform(scrollY, [0, 900], [0, 100]);

  return (
    <motion.section
      className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]"
      style={{
        background: "#f4b000",
        backgroundSize: "100% 100%",
        backgroundAttachment: "fixed",
        x,
        opacity,
      }}
      initial={{ x: "-100%", opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <div>
        <div className="text-start text-white">
          <h1 className="text-3xl mb-4">
            Nimmin is a forward-thinking organization committed to environmental
            stewardship. We aim to redefine waste by converting it into
            essential, sustainable products. Our team is passionate about
            creating a positive impact through innovative processes and
            eco-friendly practices.
          </h1>
        </div>
        <div>
          <Sustain />
        </div>
      </div>
    </motion.section>
  );
};

export default AboutCompo2;

const AboutCompo3 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px]  max-sm:px-[20px] max-sm:py-[50px]"
        style={{
          background: "#f4b000",
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="">
          <div>
            <UpValue />
          </div>
        </div>
      </section>
    </>
  );
};

const AboutCompo4 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:px-[100px]  max-sm:px-[20px] max-sm:py-[50px]"
        style={{
          background: "#f4b000",
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="">
          <div>
            <Regenerate />
          </div>
        </div>
      </section>
    </>
  );
};

const Sustain = () => {
  return (
    <>
      <section>
        <div>
          {" "}
          <h1 className="text-white text-6xl max-sm:text-4xl font-bold mt-[70px]">
            Our Mission
          </h1>
          <hr className="text-white bg-white p-1 w-[10px] my-1" />
        </div>
        <div
          className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  mt-[20px] items-center  "
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)),url(${aboutcomp2})`,
            backgroundSize: "100% 100%",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="grid grid-cols-12 gap-5">
            <div className=" lg:col-span-6 max-sm:col-span-12 sm:col-span-12 text-end ">
              <h1 className="text-3xl open-sans font-light text-white uppercase border-r px-5 border-white leading-[50px] ">
                At Nimmin, we are dedicated to crafting sustainable solutions by
                transforming agricultural waste into valuable resources.
              </h1>
            </div>

            <div className="lg:col-span-6 max-sm:col-span-12 sm:col-span-12   ">
              <div className=" col-span- px-5 text-white text-base ">
                <h1 className="text-lg leading-10">
                  Our focus is on utilizing discarded byproducts to create
                  innovative and eco-friendly products. We believe in the power
                  of sustainability to shape a greener and healthier planet for
                  future generations.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const UpValue = () => {
  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <>
      <section>
        <div>
          {" "}
          <h1 className="text-white text-6xl max-sm:text-4xl font-bold">
            Our Values
          </h1>
          <hr className="text-white bg-white p-1 w-[10px] my-1" />
        </div>
        <div
          className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  mt-[20px] items-center  "
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)),url(${aboutcomp3})`,
            backgroundSize: "100% 100%",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="grid grid-cols-12 gap-5">
            <div className="v1 lg:col-span-6 max-sm:col-span-12 sm:col-span-12 text-end ">
              <h1
                className="text-4xl open-sans font-semibold text-white uppercase border-r px-5 border-white leading-[50px]"
                data-aos="fade-up "
              >
                Sustainability
              </h1>
              <h1
                className="text-xl open-sans font-light text-white uppercase border-r px-5 border-white leading-[38px]"
                data-aos="fade-up"
              >
                We prioritize eco-friendly practices in everything we do, from
                sourcing materials to manufacturing processes.
              </h1>

              <h1
                className="text-4xl open-sans font-semibold text-white uppercase border-r mt-5 px-5 border-white leading-[50px]"
                data-aos="fade-up "
              >
                Community
              </h1>
              <h1
                className="text-xl open-sans font-light text-white uppercase border-r px-5 border-white leading-[38px]"
                data-aos="fade-up"
              >
                We priorWe believe in giving back to the community and fostering
                a culture of environmental responsibility.
              </h1>
            </div>

            <div className="v2 lg:col-span-6 max-sm:col-span-12 sm:col-span-12   ">
              <div className=" col-span- px-5 text-white text-base ">
                <h1 className="text-3xl font-semibold" data-aos="fade-down">
                  {" "}
                  Innovation
                </h1>

                <h1 className="text-xl leading-10" data-aos="fade-down">
                  We constantly seek new ways to repurpose agricultural waste,
                  leading to groundbreaking products that serve the community
                  and the environment.
                </h1>

                <h1
                  className="text-3xl font-semibold mt-5"
                  data-aos="fade-down "
                >
                  Health and Safety
                </h1>
                <h1 className="text-xl leading-10" data-aos="fade-down">
                  Our products are designed to be safe and beneficial for both
                  our customers and the planet.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Regenerate = () => {
  return (
    <>
      <section className="py-[100px] ">
        <div>
          {" "}
          <h1 className="text-white text-6xl max-sm:text-4xl font-bold">
            Our Commitment
          </h1>
          <hr className="text-white bg-white p-1 w-[10px] my-1" />
        </div>
        <div
          className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  mt-[20px] items-center  "
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0, 0.1), rgba(0,0,0, 0.1)),url(${aboutcomp4})`,
            backgroundSize: "100% 100%",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="grid grid-cols-12 gap-5">
            <div className=" lg:col-span-6 max-sm:col-span-12 sm:col-span-12 text-end ">
              <h1
                className="text-3xl open-sans font-light text-white uppercase border-r px-5 border-white leading-[50px] "
                data-aos="fade-right"
              >
                At Nimmin, we are not just a business <br /> we are a movement
                towards a sustainable future.
              </h1>
            </div>

            <div className="lg:col-span-6 max-sm:col-span-12 sm:col-span-12   ">
              <div
                className=" col-span- px-5 text-white text-base "
                data-aos="fade-right"
              >
                <h1 className="text-xl leading-10">
                  Our commitment to environmental stewardship drives us to
                  continuously improve and innovate.
                </h1>
                <h1 className="text-xl leading-10 mt-5">
                  We invite you to join us on this journey and be a part of the
                  change.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

//   -------------

const AboutUs = () => {
  return (
    <>
      <section
        className=" sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center "
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255, 0.1), rgba(255,255,255, 0.1)), url(${aboutcomp4})`,
          // backgroundSize: "100% 100%",
          // backgroundAttachment: "fixed",
        }}
      >
        <div>
          {" "}
          <h1 className="text-white text-5xl max-sm:text-4xl font-bold">
            What We Do
          </h1>
          <hr className=" bg-yellow-500 p-1 w-[20px] my-1" />
          <p className="open-sans text-white mt-4">
            We specialize in turning agricultural waste into a range of
            sustainable products. Our offerings are designed to meet basic needs
            while being healthier, safer, smarter, and more desirable. Each
            product is crafted with the highest standards of sustainability in
            mind, ensuring minimal environmental impact.
          </p>
        </div>
        <div className="flex justify-center">
          <div className="mt-10">
            <div className="grid grid-cols-12 gap-5">
              <div className=" lg:col-span-6 max-sm:col-span-12 sm:col-span-12 p-3 bg-white rounded text-center">
                <div className="flex items-center gap-4">
                  <img
                    className="h-[80px] my-5"
                    src={complogo1}
                    alt="company-logo"
                  />
                  <h1 className=" text-xl font-bold mt-4">GYPRC GROUP</h1>
                </div>
                <p className="text-sm leading-6 open-sans  mt-5 ">
                  GYPRC is a dynamic venture comprising 5 unique companies, each
                  contributing its strengths and expertise to create a brighter
                  future. Our collective mission is to foster innovation,
                  sustainability, and positive change across various industries.
                  Together, we are GYPRC – a force for positive change,
                  innovation, and collaboration.
                </p>
                <p className="text-end text-sm leading-6 open-sans  mt-2 font-semibold italic ">
                  From Chairman
                </p>

                <hr className=" my-2 " />
              </div>

              <div className=" lg:col-span-6 max-sm:col-span-12 sm:col-span-12 p-3 bg-white rounded text-center">
                <div className="flex items-center gap-4  ">
                  <img
                    className="h-[60px] my-5"
                    src={complogo3}
                    alt="company-logo"
                  />

                  <div>
                    <img
                      className="h-[22px] my-1"
                      src={complogo2}
                      alt="company-logo"
                    />

                    <p className="font-semibold ">
                      {" "}
                      <span className="text-green-700 ">Prakriti</span> Nahi
                      <span className="text-green-700"> Pravriti</span> Badlen!
                    </p>
                  </div>
                </div>
                <p className="text-sm leading-6 open-sans  mt-5  ">
                  "At GYPRC, we believe in the transformative power of
                  collaboration and innovation. Our diverse group of companies
                  works synergistically to tackle some of the world's most
                  pressing challenges. Each member of the GYPRC family brings
                  unique strengths to the table, and together, we are committed
                  to driving sustainable growth and creating lasting positive
                  impact. We are proud of the strides we have made and excited
                  about the future we are building. Join us on this journey
                  towards a better tomorrow."
                  <p className="text-end text-sm leading-6 open-sans  mt-2 font-semibold italic ">
                    Mr. Ambrish Anand
                  </p>
                </p>

                <hr className=" my-2 " />
              </div>
            </div>

            <div className=" lg:col-span-6 max-sm:col-span-12 sm:col-span-12  mt-14 bg-white p-5 rounded">
              <h1 className="text-gray-600 text-3xl font-semibold mt-4">
                How GYPRC is Creating Change
              </h1>
              <p className="text-xl leading-6  mt-5 text-gray-500  ">
                GYPRC is at the forefront of fostering innovation and
                sustainability across various sectors. <br /> Our impact
                includes.
              </p>
              <div className="lg:col-span-6 text-gray-500 max-sm:col-span-12 sm:col-span-12   ">
                <div className=" col-span text-base ">
                  <h1 className="text-lg mt-5">
                    <span className="text-xl font-semibold text-gray-600 mt-5">
                      Education :{" "}
                    </span>
                    Through TeacherCool.com, we empower individuals with the
                    skills needed to succeed in the IT sector, contributing to
                    workforce development and economic growth.
                  </h1>
                  <h1 className="text-lg mt-5">
                    <span className="text-xl font-semibold text-gray-600 mt-5">
                      Healthcare :{" "}
                    </span>
                    EquipMedy.com enhances healthcare accessibility by providing
                    a reliable platform for medical equipment and supplies,
                    improving the quality of care.
                  </h1>
                  <h1 className="text-lg mt-5">
                    <span className="text-xl font-semibold text-gray-600 mt-5">
                      Sustainability :{" "}
                    </span>
                    Nimmin transforms organic waste into eco-friendly products,
                    reducing environmental impact and promoting sustainable
                    living.
                  </h1>
                  <h1 className="text-lg mt-5">
                    <span className="text-xl font-semibold text-gray-600 mt-5">
                      Culinary Arts :{" "}
                    </span>
                    Tingletaste.com celebrates cultural heritage and culinary
                    innovation, offering delightful dining experiences that
                    connect people to the rich flavors of India.
                  </h1>
                  <h1 className="text-lg mt-10 text-center">
                    {" "}
                    By integrating our efforts, GYPRC is not only addressing
                    individual industry needs but also contributing to a larger
                    vision of sustainable and positive global development.{" "}
                  </h1>
                </div>
              </div>
              <hr className=" my-2 " />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
