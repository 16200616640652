import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import varmicomp1 from "../../assets/images/nimmin/products/vormiType1_1.png";
import varmicomp2 from "../../assets/images/nimmin/products/vormiType1_4.png";
import varmicomp3 from "../../assets/images/nimmin/products/VermiType2.13.png";
import vermiImg from "../../assets/images/nimmin/products/vermibgImg.png";




import { FaCloudDownloadAlt } from "react-icons/fa";

export const VermiCompost = () => {
  return (
    <>
      <AgroSatvaCompo1 />
    </>
  );
};

const AgroSatvaCompo1 = () => {

  const handleLabelClick = () => {
    const pdfUrlpath = process.env.PUBLIC_URL + '/vermi_compost_browser.pdf';
    downloadPdf(pdfUrlpath);
};

const downloadPdf = (url) => {
    const path = document.createElement('a');
    path.href = url;
    path.setAttribute('download', 'vermi_compost_browser.pdf');
    document.body.appendChild(path);
    path.click();
    document.body.removeChild(path); 
};
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <VermiCompostSlide />
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={AgroSatvaimg3} alt="" /> */}
                <h1 className="text-5xl font-bold pb-2">Vermi Compost</h1>
              </div>
              <ul className=" list-disc ">
                <li className=" text-lg leading-8  ">
                  {" "}
                  Nimmin's Vermicompost is a nutrient-rich organic fertilizer produced through the decomposition of organic waste by earthworms. This eco-friendly product enhances soil health and boosts plant growth by providing essential nutrients, improving soil structure, and increasing microbial activity.
                </li>
                <li className=" text-lg leading-10  ">
                Increases beneficial microbial activity in the soil, supporting plant health and disease resistance.
                </li>
                <li className=" text-lg leading-10  ">
                Unlike chemical fertilizers, vermicompost is natural, odorless, and safe for all plants.
                </li>
              </ul>
              <div className="flex px-3  justify-center items-center text-center bg-green-700 py-1 w-[160px] text-white rounded-full">
                <div>
                  {" "}
                  <FaCloudDownloadAlt className="text-lg font-semibold" />
                </div>
                <div>
                  <button className=" text-lg  font-semibol " onClick={handleLabelClick}>
                    Download Browser
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="w-full max-sm:mt-[50px] mt-[100px] " />
        </div>
        <div className=" container mx-auto justify-center   max-lg:py-[90px] max-sm:px-0  relative sm:mb-[296px] xl:mb-[60px] xl:mt-[60px] ">
        <img className=" h-[450px] max-sm:h-auto w-full rounded" src={vermiImg} alt="" />
      </div>

        <div className="max-sm:mt-[0px] mt-[100px]">
          <div>
            <h1 className="text-white text-5xl max-sm:text-4xl font-bold max-sm:mt-[0px] mt-[100px] ">
              Features Vermi Compost
            </h1>
            <hr className=" bg-yellow-500 p-1 w-[30px] my-4" />
          </div>
          
          <div className="bg-white bg-opacity-40 rounded-3xl">
            <div className="bg-gray-400 rounded-3xl pt-3 pl-3 pr-3">
              <Tabs />
            </div>
          </div>
        </div>

     
      </section>
    </>
  );
};

// ---------------
const Tabs = () => {
  const [activeTab, setActiveTab] = useState("WhyAgroSatva");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="w-full mx-auto  bg-gray-200 rounded-3xl max-sm:px-2 px-4">
      <div className="flex justify-center border-b  w-full border-gray-300">
        <button
          className={`py-3 max-sm:py-1 px-4 max-sm:px-1  text-gray-700  text-lg max-sm:text-sm font-semibold  ${
            activeTab === "WhyAgroSatva" ? "bg-white rounded-2xl " : "text-black   "
          }`}
          onClick={() => handleTabClick("WhyAgroSatva")}
        >
          Why Vermi Compost
        </button>
        <button
          className={`py-3 max-sm:py-1 max-sm:px-1  text-gray-700  text-lg max-sm:text-sm font-semibold  px-4 ${
            activeTab === "DosageApplications" ? "bg-white rounded-2xl" : "text-gray-500"
          }`}
          onClick={() => handleTabClick("DosageApplications")}
        >
          Dosage & Applications
        </button>
      </div>
      <div className="mt-4">
        {activeTab === "WhyAgroSatva" && (
          <div>
            <h2 className="text-xl font-bold mb-2">Why AgroSatva</h2>
            <ul className="">
              <li className="textt-base leading-10 mt-5">
           
                • Nutrient-Rich: Contains essential nutrients like nitrogen, phosphorus, and potassium, which are vital for plant growth.
              </li>
              <li className="textt-base leading-10 mt-5">
                • Improves Soil Structure: Enhances soil aeration, water retention, and drainage, leading to healthier plant roots.
              </li>
              <li className="textt-base leading-10 mt-5">
                • Environmentally Friendly: Made from organic waste, reducing landfill waste and promoting sustainable agriculture.
              </li>

              <li className="textt-base leading-10 mt-5">
              • Microbial Boost: Increases beneficial microbial activity in the soil, supporting plant health and disease resistance.
              </li>

              <li className="textt-base leading-10 ">
              • Odor-Free and Easy to Use: Unlike chemical fertilizers, vermicompost is natural, odorless, and safe for all plants.
              </li>
              
            </ul>
          </div>
        )}
        {activeTab === "DosageApplications" && (
          <div className="w-full overflow-x-auto">
          <h2 className="text-xl font-bold mb-2">Dosage & Applications</h2>
          <div className="container mx-auto max-sm:p-2 p-4">

          <ul>
           <li className="textt-base leading-10 ">
           • For Potted Plants: Mix 1-2 tablespoons of Nimmin's Vermicompost into the soil for small to medium-sized pots. For larger pots, use 2-3 tablespoons.
            </li>
            <li className="textt-base leading-10 ">
           • For Garden Beds: Apply 1-2 kg of vermicompost per square meter of garden bed. Mix it well into the topsoil.
            </li>
            <li className="textt-base leading-10 ">
            For Trees and Shrubs: Use 2-3 kg of vermicompost per tree or shrub, applied around the base, and lightly mixed into the soil.
            </li>
            <li className="textt-base leading-10 ">
        For Lawns: Spread 1-2 kg of vermicompost evenly per square meter of lawn area. Water thoroughly after application.
            </li>
           </ul>

          </div>
        </div>
        
        )}
      </div>
    </div>
  );
};

export default Tabs;

// component 2



export const VermiCompostSlide = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-xl" src={varmicomp1} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex  justify-center ">
              <img className="h-[380px] rounded-xl" src={varmicomp2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex  justify-center ">
              <img className="h-[380px] rounded-xl" src={varmicomp3} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


