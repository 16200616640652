import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import AgroSatvacomp1 from "../../assets/images/nimmin/agr/asset7.jpeg";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { AboutCont } from "../Contact";
import packaging1 from "../../assets/images/nimmin/products/newproduct/packaging.jpg";

export const AgroPurnaSlider = () => {
  return (
    <>
      <div className=" py-5">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".button-next",
            prevEl: ".button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex   justify-center ">
              <img className="h-[380px] rounded-3xl" src={packaging1} alt="" />
            </div>
          </SwiperSlide>
          
        </Swiper>
        <div className=" relative">
          <div className=" ">
            <div className="button-next absolute top-[-210px] z-20 cursor-pointer right-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200 ">
              <BiChevronRight className="text-4xl" />
            </div>
            <div className="button-prev absolute top-[-210px] z-20 cursor-pointer left-4 bg-black bg-opacity-30 w-[45px] p-1 text-gray-200">
              <BiChevronLeft className="text-4xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Packaging = () => {
  return (
    <>
      <ContactCompo1 />
      <AboutCont />
    </>
  );
};

const ContactCompo1 = () => {
  return (
    <>
      <section
        className="HomeCompo1 sm:p-[100px] max-sm:px-[20px] max-sm:py-[50px]  items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0, 0.2)),url(${AgroSatvacomp1})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="mt-[100px]">
          <div className="container grid grid-cols-12 bg-white bg-opacity-40 rounded-3xl">
            <div className=" lg:col-span-5 sm:col-span-full max-sm:col-span-full">
              <AgroPurnaSlider />
            </div>
            <div className="lg:col-span-7  p-6  sm:col-span-full max-sm:col-span-full ">
              <div>
                {/* <img className="h-[90px]" src={agropurnalogo} alt="" /> */}
                <h1 className="text-center text-5xl  max-sm:text-3xl  font-bold">
                  {" "}
                  Bio Fibre Packaging
                </h1>
              </div>
              <ul className=" list-disc ">
                <li className="py-2">
                  Bio fibre packaging products are a sustainable and
                  eco-friendly alternative to conventional packaging materials.
                  Derived from the banana plant’s pseudo-stems, which are
                  typically agricultural waste, these fibers are strong,
                  durable, and biodegradable. The packaging products made from
                  banana bio fibers are not only environmentally friendly but
                  also offer excellent strength and flexibility, making them
                  suitable for various uses, including food packaging, shopping
                  bags, and protective packaging. This innovation reduces
                  plastic waste and promotes the use of renewable resources.
                  Additionally, the production of banana bio fibre packaging
                  supports sustainable agricultural practices, providing
                  economic benefits to banana farmers and contributing to a
                  circular economy.
                </li>
              </ul>
            </div>
          </div>

          <hr className="w-full mt-[100px] " />
        </div>
      </section>
    </>
  );
};
